import React from "react";
import LoginPopup from "../Popup";
import { useEffect, useState } from "react";
import { useStore, StoreTypes } from "context";
import { LOGIN_POPUP } from "constants/loginTypes";
import styles from "../Popup/index.module.scss";
import { useLocation } from "react-router-dom";
import { gaEventQrcodeLogin } from "components/AppService/GAService";
import { CategoryType } from "constants/gaEventType";
import { useParams } from "react-router-dom";
import { QrcodeLoginType } from "constants/contentType";
import {useTranslation} from "react-i18next";

const QrcodeLogin = () => {
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.login.qrcodeLogin.${key}`)
  };

  const [{ popupState }, loginDispatch] = useStore(StoreTypes.login);
  const [onepassStatus, setOnepassStatus] = useState();
  const param = useParams();
  const qrcodeId = document.cookie
    .split(";")
    .find((doc) => doc.includes("qrcodeId"));
  const qrcodeToken = document.cookie
    .split(";")
    .find((doc) => doc.includes("nani_oneclass_login_token"));
  const qrcodeIdPath = useLocation().search.replace("?uuid=", "");
  useEffect(() => {
    loginDispatch({ type: LOGIN_POPUP, popupState: true });
    if (qrcodeIdPath) {
      document.cookie = "qrcodeId=" + qrcodeIdPath + ";";
    }
  }, [loginDispatch, param, qrcodeIdPath]);

  useEffect(() => {
    const addTokenApi = async () => {
      let addTokenRes = "";
      const qrcodeIdContent =
        qrcodeId === undefined
          ? null
          : qrcodeId.replace("qrcodeId=", "").replace(" ", "");
      const qrcodeTokenContent =
        qrcodeToken === undefined
          ? null
          : JSON.parse(qrcodeToken.replace("nani_oneclass_login_token=", ""));
      const data = { id: qrcodeIdContent };

      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/qrcode/add`,
          {
            method: "POST",
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization:
                qrcodeTokenContent !== null ? qrcodeTokenContent.jwt : null,
            }),
            body: JSON.stringify(data),
          }
        );
        addTokenRes = await res.json();
        gaEventQrcodeLogin({
          category: CategoryType.OnePass,
          action: QrcodeLoginType.sent,
          label: trans("__userScannedSubmitLoginRequest"),
        });
        if (addTokenRes.status === "success") {
          setOnepassStatus(trans("__loginSuccess"));
          loginDispatch({ type: LOGIN_POPUP, popupState: false });

        }
        return addTokenRes;
      } catch (error) {
        console.error(error);
      }
      return addTokenRes;
    };
    addTokenApi();
  }, [qrcodeId, qrcodeToken]);

  return (
    <>
      {popupState === false ? (
        <h2 className={styles.qrcodeLoginHint}>{onepassStatus}</h2>
      ) : (
        <LoginPopup
          onChangeOnepassStatus={(status) => {
            document.cookie = 'qrcodeId=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
            switch (status) {
              case "please reload qrcode page": {
                setOnepassStatus(trans("__pageErrorRegenerateQR"));
                break;
              }
              case "success": {
                setOnepassStatus(trans("__loginSuccess"));
                break;
              }
              default: {
                setOnepassStatus(trans("__unknownErrorContactDeveloper"));
                break;
              }
            }
          }}
        />
      )}
    </>
  );
};

export default QrcodeLogin;

