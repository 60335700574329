import React, { useEffect, useState } from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import { checkImgExists } from 'util/checkImgExists';
import { useTranslation } from "react-i18next";

const Card = ({ book = {}, BookContentRepository, openBook, alertShelfDialog }) => {
    const [imgExists, setImgExists] = useState(false);
    const imgCover = BookContentRepository.getCoverUrls({ bookId: book.bookId });
    const imgErrorCover = 'assets/img/cover.jpg';
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.bookshelf.card.${key}`)
    };
    useEffect(() => {
        checkImgExists(imgCover).then(() => {
            setImgExists(true);
        }).catch(() => {
            setImgExists(false)
        })
    }, [])

    return (
        <li className={styles['list-item']} key={book.id}>
            <div className={styles['list-img']} onClick={() => openBook(book)}
                style={{ backgroundImage: `url(${imgExists ? imgCover : imgErrorCover})` }}></div>
            <div className={styles['list-title']}>{book.displayName}</div>
            <div className={styles['list-tool']}>
                <div onClick={() => alertShelfDialog(book.bookId, book.id)}>
                    <Icon name='trashAlt' type="text" className={styles.trash} />
                </div>
                <button className={styles.btn} onClick={() => openBook(book)}>{trans('__enterBook')}</button>
            </div>
        </li>
    )
};
export default Card;
