import React, { useCallback } from "react";
import { useBoolean } from "customHooks/boolean";
import PlaybackRateControl from "../PlaybackRateControl";
import styles from "./index.module.scss";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const WordsVideoControl = ({
  previousButtonClick,
  previousButtonDisabled,
  nextButtonClick,
  nextButtonDisabled,
  repeatButtonClick,
  repeatDisabled,
  playbackRate,
  onChangePlaybackRate,
  onPlayAllWordsClick,
}) => {
  const { t } = useTranslation();
  const [isPlaying, { toggle }] = useBoolean(false);

  const onPlayAllWordsClickHandler = useCallback(
    (e) => {
      onPlayAllWordsClick(!isPlaying);
      toggle();
    },
    [onPlayAllWordsClick, isPlaying, toggle]
  );

  return (
    <>
      <div className={styles.buttonGroup}>
        <button
          className={classnames({ [styles.active]: isPlaying })}
          onClick={onPlayAllWordsClickHandler}
        >
          {t("components.wordLearningGrid.wordsVideoControl.__playAllWords")}
        </button>
        <button onClick={previousButtonClick} disabled={previousButtonDisabled}>
          {t("components.wordLearningGrid.wordsVideoControl.__previousButton")}
        </button>
        <button onClick={nextButtonClick} disabled={nextButtonDisabled}>
          {t("components.wordLearningGrid.wordsVideoControl.__nextButton")}
        </button>
        <button
          className={styles.singleRepeat}
          onClick={repeatButtonClick}
          disabled={repeatDisabled}
        >
          {t("components.wordLearningGrid.wordsVideoControl.__singleRepeat")}
        </button>
        <PlaybackRateControl
          onChange={onChangePlaybackRate}
          value={playbackRate}
        />
      </div>
    </>
  );
};

export default WordsVideoControl;
