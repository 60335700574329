import React, { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { CanvasGroupAlignType,CanvasGroupSplitGapType } from 'constants/alignTypes';
import Icon from 'components/Icon';
import {useTranslation} from "react-i18next";

const AlignTools = () => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.alignTools.${key}`)
    };
  const [{ canvas, activeCanvasObject }] = useStore(StoreTypes.canvas);

  const setAlign = useCallback((align) => {
    const horizontalCenter = (activeCanvasObject.width * activeCanvasObject.scaleX) / 2,
      verticalCenter = (activeCanvasObject.height * activeCanvasObject.scaleY) / 2,
      { width, height } = canvas;

    switch (align) {
      case 'top':
        activeCanvasObject.set({ top: verticalCenter })
        break
      case 'left':
        activeCanvasObject.set({ left: horizontalCenter })
        break
      case 'bottom':
        activeCanvasObject.set({ top: height - verticalCenter })
        break
      case 'right':
        activeCanvasObject.set({ left: width - horizontalCenter })
        break
      case 'center':
        activeCanvasObject.set({ left: (width / 2) })
        break
      case 'middle':
        activeCanvasObject.set({ top: (height / 2) })
        break
      default:
        activeCanvasObject.set({ top: (height / 2) })
    }

    activeCanvasObject.setCoords()
    canvas.renderAll()
  }, [activeCanvasObject, canvas]);

  const alignGroupHandler = useCallback((alignType) => e => {
    EventBus.emit({ event: CanvasEvent.AlignCanvasGroupEvent, payload: { canvasObject: activeCanvasObject, alignType } });
  }, [activeCanvasObject]);

  return (
    <div className={styles.alignTools}>
      <button onClick={() => setAlign('top')}>top</button>
      <button onClick={() => setAlign('right')}>right</button>
      <button onClick={() => setAlign('left')}>left</button>
      <button onClick={() => setAlign('bottom')}>bottom</button>
      <button onClick={() => setAlign('center')}>center</button>
      <button onClick={() => setAlign('middle')}>middle</button>
      <h3>
          {trans("__objectAlignment")}
      </h3>

      <div className={styles.alignGroup}>
        {Object.keys(CanvasGroupAlignType).map((type, i) => <button key={i} onClick={alignGroupHandler(CanvasGroupAlignType[type])}>
          <Icon name={CanvasGroupAlignType[type]}/>
        </button>)}
      </div>
      <h3>
          {trans("__equalDistribution")}
      </h3>
      <button onClick={alignGroupHandler(CanvasGroupSplitGapType.HorizontallySplitGap)}>{trans("__horizontalEqualSpacing")}</button>
      <button onClick={alignGroupHandler(CanvasGroupSplitGapType.VerticallySplitGap)}>{trans("__verticalEqualSpacing")}</button>
    </div>
  )
};

export default AlignTools;
