import React from 'react';
import Input from 'components/common/Input';
import { ExtraFieldsForEditor } from 'constants/canvas';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";

const LISTENING_INFO = ExtraFieldsForEditor.ListeningInfo;

export const ListeningStoryComponent = ({ object, saveProperty }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.propertyForm.listeningStoryPropertyForm.${key}`)
    };

  let listeningInfo = object[LISTENING_INFO] ? JSON.parse(object[LISTENING_INFO]) : { tab: '', zhuyin: '' }

  const changeHandler = type => e => {
    listeningInfo[type] = type === 'zhuyin' ? e.target.checked : e.target.value;
    saveProperty(JSON.stringify(listeningInfo))
  };

  return (
    <>
      <Input type="number" className={styles.vocabularyInput} label={trans("__defaultTab")} value={listeningInfo.tab} onChange={changeHandler('tab')} />
      <Input type="checkbox" checked={listeningInfo.zhuyin} label={trans("__purePhonetic")} onChange={changeHandler('zhuyin')} />
    </>
  )
};