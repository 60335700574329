import React, { useCallback } from "react";
import Icon from "components/Icon";
import { useBoolean } from "customHooks/boolean";
import classnames from "classnames";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const WordsMovieButtonGroup = ({
  isPlaying,
  wordingMap,
  data,
  activeIndex,
  onChange = () => {},
  play = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.buttonGroup}>
      {data.map(
        (item, index) =>
          wordingMap[index] && (
            <button
              key={item.Path}
              className={classnames({ [styles.active]: index === activeIndex })}
              onClick={() =>
                onChange({
                  index,
                  value: item.Path,
                })
              }
            >
              {wordingMap[index]}
            </button>
          )
      )}
      <button onClick={play}>
        {isPlaying
          ? t("components.wordLearningGrid.wordsMovieButtonGroup.__pause")
          : t("components.wordLearningGrid.wordsMovieButtonGroup.__play")}
      </button>
    </div>
  );
};

export default WordsMovieButtonGroup;
