import React from 'react';
import Button from 'components/Button/index';
import Icon from 'components/Icon';
import { PainterType } from 'constants/painterTypes';
import styles from './index.module.scss';
import classnames from 'classnames';
import {useTranslation} from "react-i18next";

const ShapeSelector = ({ fillType, onFillTypeChange = () => { }, onChange, painterType }) => {

  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.painter.shapeSelector.${key}`)
  };

  const fillTypeData = [
    { value: trans("__hollow"), label: '空心' },
    { value: trans("__solid"), label: '實心' }
  ];
  return (
    <div className={styles.shapeSelector}>
      <div className={styles.fillRadio}>
        {fillTypeData.map(item => (
          <label key={item.value}>
            <input
              type="radio"
              name="shapeSelector"
              value={item.value}
              checked={fillType === item.value}
              onChange={e => onFillTypeChange(e.target.value)}
            />
            <span className={styles.text}>{item.label}</span>
          </label>
        ))}
      </div>
      <div className={styles.buttonGroup}>
        <Button
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.RightTriangle })}
          onClick={() => onChange(PainterType.RightTriangle)}
        >
          <Icon name="RightTriangle" />
        </Button>
        <Button
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.IsoscelesTriangle })}
          onClick={() => onChange(PainterType.IsoscelesTriangle)}
        >
          <Icon name="IsoscelesTriangle" />
        </Button>
        <Button
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.Circle })}
          onClick={() => onChange(PainterType.Circle)}
        >
          <Icon name="Circle" />
        </Button>
        <Button
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.Rectangle })}
          onClick={() => onChange(PainterType.Rectangle)}
        >
          <Icon name="Rectangle" />
        </Button>
        <Button
          className={classnames(styles.button, { [styles.active]: painterType === PainterType.Star })}
          onClick={() => onChange(PainterType.Star)}
        >
          <Icon name="Star" />
        </Button>
      </div>
    </div>
  );
};
export default ShapeSelector;
