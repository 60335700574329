import React,{ useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';

import styles from './index.module.scss';
import {useTranslation} from "react-i18next";
import {fixCssButton} from "../FlipTools";

const AngleSelector = ({ target:object }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.angleSelector.${key}`)
    };
    const setAngleHandler = useCallback((property,value) => {
        if (object) {
            EventBus.emit({
                event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
                payload: { object, property, value:value%360 }
            });
        }
    }, [object]);

    return(
        <div className={styles.angle}>
            <div className={styles.head}>
                <span>{trans("__angle")}</span>
                <input style={fixCssButton} type="number" value={object.angle} onChange={e=>{
                        const {value} = e.target;
                        setAngleHandler('angle',parseInt(value));
                }}/>
            </div>
            <button style={fixCssButton} onClick={()=>setAngleHandler('angle',90 + parseInt(object.angle))}>{trans("__rotate90Degrees")}</button>
        </div>
    )
};

export default AngleSelector;