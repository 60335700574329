import React, { useCallback } from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";

export const InternalHtmlWindowComponent = ({ object, saveProperty }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.propertyForm.internalHtmlWindowComponent.${key}`)
    };

    const internalHtmlInfo = object.internalHtmlInfo ? JSON.parse(object.internalHtmlInfo) : { src: "", toolbarOpen: false }

    const changeSelect = useCallback((value) => {
        internalHtmlInfo.toolbarOpen = value;
        saveProperty(JSON.stringify(internalHtmlInfo))
    }, [internalHtmlInfo, saveProperty]);

    const srcChangeHandler = useCallback((value) => {
        internalHtmlInfo.src = value;
        saveProperty(JSON.stringify(internalHtmlInfo))
    }, [internalHtmlInfo, saveProperty]);

    return (
        <div>
            <hr></hr>
            <Input
                className={styles.internalHtmlInput}
                label="Src"
                value={internalHtmlInfo.src}
                onChange={(e) => srcChangeHandler(e.target.value)}
            />
            <Input
                type="checkbox"
                label={trans("__openToolbar")}
                value={object.toolbarOpen}
                checked={internalHtmlInfo.toolbarOpen}
                onChange={(e) => changeSelect(e.target.checked)}
            />
        </div>
    );
}