import React, { useState, useEffect, useCallback, useRef } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { getDeviceXY } from 'util/device';
import Repository from 'repositories/Repository';
import {useTranslation} from "react-i18next";
const { ExtendedResourceRepository } = Repository;

const PageTools = () => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.offlineTools.pageTools.${key}`)
    };

    const [{ pageIndex, isDoublePageMode }] = useStore(StoreTypes.reader);
    const [{ bookId }] = useStore(StoreTypes.books);
    const [tools, setTools] = useState([]);
    const [displayToolBox, SetDisplayToolBox] = useState(true);
    const [isMove, setIsMove] = useState(0);
    const [rect, setRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
    const containerRef = useRef();
    const rectRef = useRef();
    const buttonRef = useRef();

    useEffect(() => {
        bookId && (async () => {
            const tools = await ExtendedResourceRepository.getPageTools({ bookId });
            setTools(tools);
        })();
    }, [bookId]);

    const mouseDownHandler = useCallback((e) => {
        if (e.target !== buttonRef.current) return;
        setIsMove(1)
        const { deviceX, deviceY } = getDeviceXY(e)
        setRect({ x: deviceX - rectRef.current.offsetLeft, y: deviceY - rectRef.current.offsetTop });
    }, [])

    const mouseMoveHandler = useCallback((e) => {
        if (isMove === 0) { return; }
        const { deviceX, deviceY } = getDeviceXY(e)
        const { x, y } = rect;
        rectRef.current.style.right = `${(containerRef.current.offsetWidth - (deviceX - x) - rectRef.current.offsetWidth)}px`
        rectRef.current.style.bottom = `${(containerRef.current.offsetHeight - (deviceY - y + rectRef.current.offsetHeight))}px`
        setIsMove(2);
    }, [isMove, rect])

    const mouseUpHandler = useCallback((e) => {
        if (isMove === 1) {
            SetDisplayToolBox(!displayToolBox);
        }
        setIsMove(0);
    }, [displayToolBox, isMove])

    const displayableTools = useCallback(() => {
        let match = [];
        if (tools) {
            let currentTargetPages = [pageIndex];
            if (isDoublePageMode) {
                const alignPage = pageIndex * 2;
                currentTargetPages = [alignPage, alignPage + 1];
            }

            for (let page of currentTargetPages) {
                if (tools.getToolsByPage) {
                    const currentTargetPageTools = tools.getToolsByPage(page);
                    match = [...new Set([...match, ...currentTargetPageTools])];
                }
            }
        }
        return match;
    }, [isDoublePageMode, pageIndex, tools]);

    const clickToolHandler = useCallback(({ filename }) => e => {
        ExtendedResourceRepository.postTools({ bookId, filename });
    }, [bookId]);

    const closeHandler = useCallback(() => {
        SetDisplayToolBox(false);
    }, []);

    return (
        <div className={classnames(styles.pageToolDragZone, styles[isMove !== 0 ? 'show' : null])}
            ref={containerRef}
            onMouseDown={mouseDownHandler}
            onMouseMove={mouseMoveHandler}
            onMouseUp={mouseUpHandler}
        >
            {(displayableTools().length > 0) && <div className={classnames(styles.pageTool)} ref={rectRef}>
                <div className={classnames(styles.container, { [styles.show]: displayToolBox })}>
                    <div className={styles.closeBtn} onClick={closeHandler}>
                        <Icon type="text" name="times" />
                    </div>
                    <div className={styles.content}>
                        {displayableTools().map((tool) => {
                            return (
                                <div className={styles.btn} onClick={clickToolHandler(tool)}>
                                    <Icon className={styles.icon} name={tool.icon} type='fa' />
                                    <div className={styles.text}>{tool.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.pageToolButton} ref={buttonRef}>
                    <Button
                        title={trans("__pageToolbox")}
                    >
                        <Icon name='PageToolBox' />
                    </Button>
                </div>
            </div>}
        </div>
    );
};

export default PageTools;
