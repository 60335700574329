import React, { useState, useCallback, useEffect } from "react";
import Icon from "components/Icon";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const levels = [
  { label: "1", value: 0.5 },
  { label: "2", value: 0.75 },
  { label: "3", value: 1 },
  { label: "4", value: 1.2 },
  { label: "5", value: 1.55 },
  { label: "6", value: 2 },
];

const PlaybackRateControl = ({ value = 1, onChange = () => {} }) => {
  const { t } = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(2);

  const clickHandler = useCallback(
    (value, index) => {
      setCurrentIndex(index);
      onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    const index = levels.findIndex((item) => item.value === value);
    setCurrentIndex(index);
  }, [value]);

  const controlHandler = () => {
    const nextIndex =
      currentIndex + 1 > levels.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);
    const value = levels[nextIndex].value;
    clickHandler(value, nextIndex);
  };

  return (
    <div className={styles.playbackRateControl} onClick={controlHandler}>
      <div className={styles.controls}>
        {t("components.wordLearningGrid.playbackRateControl.__speed", "速度")}(
        {currentIndex + 1}x)
      </div>
    </div>
  );
};

export default PlaybackRateControl;
