import React, { useCallback, useEffect } from 'react';
import useSetState from 'customHooks/setState';
import Button from 'components/Button';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";

export const AudioComponent = ({
    value = [],
    onChange = () => { }
}) => {

    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.propertyForm.audioPropertyForm.${key}`)
    };

    const [{ dataList }, setState] = useSetState({
        dataList: value
    });

    const addNewSrcFiled = useCallback(() => {
        const temp = dataList.concat();
        temp.push('');
        setState({ dataList: temp });
    }, [dataList, setState]);


    const inputChangeHandler = useCallback(index => e => {
        let tempDataList = dataList.concat();
        tempDataList[index] = e.target.value.trim();
        tempDataList = tempDataList.filter(item => item)
        setState({ dataList: tempDataList });
        onChange(tempDataList);
    }, [dataList, onChange, setState]);

    const transDataType = data => {
        return typeof data === 'string' ? [data] : data;
    };

    return (
        <div className={styles.audioComponent}>
            <Button className={styles.addSrcButton} onClick={addNewSrcFiled}>{trans("__addSource")}</Button>
            {
                transDataType(dataList).map((item, index) => (
                    <Input
                        className={styles.input}
                        label={`${trans("__segmentedAudio")} ${index + 1}`}
                        value={item}
                        onChange={inputChangeHandler(index)}
                    />
                ))
            }
        </div>
    )

};
