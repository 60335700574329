import React, { useCallback, useState, useEffect } from 'react';
import ControlButton from 'components/common/Player/ControlButton';
import {useTranslation} from "react-i18next";

const SubtitleControl = ({ subtitles = [], onChange, value = [] }) => {
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.common.player.subtitleControl.${key}`)
  };
  const [selectedLang, selected] = useState({
    'zh-TW': value.includes('zh-TW'),
    en: value.includes('en')
  });

  useEffect(() => {
    let langValues = [];
    Object.keys(selectedLang).forEach(lang => {
      selectedLang[lang] && langValues.push(lang);
    });
    onChange(langValues);
  }, [onChange, selectedLang]);

  const handler = useCallback(
    lang => {
      selected({ ...selectedLang, [lang]: !selectedLang[lang] });
    },
    [selected, selectedLang]
  );

  return (
    <>
      {subtitles.map(subtitle => (
        <ControlButton
          key={subtitle.language}
          active={selectedLang[subtitle.language]}
          onClick={() => handler(subtitle.language)}
        >
          {subtitle.language === 'en' ? trans("__english") : trans("__chinese")}
        </ControlButton>
      ))}
    </>
  );
};

export default SubtitleControl;
