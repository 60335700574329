import React, { useCallback } from 'react';
import styles from './index.module.scss';
import AlignTools from 'components/Tools/AlignTools';
import AngleSelector from 'components/Tools/AngleSelector';
import BasePosition from 'components/Tools/BasePosition';
import BaseSize from 'components/Tools/BaseSize';
// import ColorSelector from 'components/Tools/ColorSelector';
// import FillColorSelector from 'components/Tools/FillColorSelector';
import FlipTools from 'components/Tools/FlipTools';
import LevelTools from 'components/Tools/LevelTools';
import LineSelector from 'components/Tools/LineSelector';
import ObjectColorSelector from 'components/Tools/ObjectColorSelector';
import OpacityTools from 'components/Tools/OpacityTools';
// import PathTools from 'components/Tools/PathTools';
import ShadowTools from 'components/Tools/ShadowTools';
import LockTools from 'components/Tools/LockTools';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import {useTranslation} from "react-i18next";

const ObjectStylePanel = () => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.sideToolContents.canvasObjectPropertyViewer.objectStylePanel.${key}`)
    };


    const reducers = useStore();
  const [{ activeCanvasObject }] = reducers[StoreTypes.canvas];
  const { type, extra } = activeCanvasObject;

  const copyHandler = useCallback(
    () =>
      EventBus.emit({
        event: CanvasEvent.CanvasObjectCopyEvent,
        payload: { object: activeCanvasObject }
      }),
    [activeCanvasObject]
  );
  const toggleGroupHandler = useCallback(
    e => {
      EventBus.emit({
        event: CanvasEvent.ToggleCanvasGroupEvent,
        payload: { object: activeCanvasObject }
      });
    },
    [activeCanvasObject]
  );

  return (
    <>
      <div className={styles.buttonGroup}>
        <button onClick={copyHandler}>{trans("__copy")}</button>
        <button onClick={toggleGroupHandler}>
          {type === 'group' && !(extra && extra.stamp) ? trans("__ungroup") : trans("__group")}
        </button>
      </div>
      <div className={styles.division_line} />
      <LockTools target={activeCanvasObject} />
      <div className={styles.division_line} />
      <BaseSize target={activeCanvasObject} />
      <BasePosition target={activeCanvasObject} />
      <div className={styles.division_line} />
      {/* {colorSelectorTool && (
                <ColorSelector title="顏色" target={activeCanvasObject} />
            )} */}

      <ObjectColorSelector
        title={trans("__fill")}
        propertyName="fill"
        target={activeCanvasObject}
      />
      <div className={styles.division_line} />
      <ObjectColorSelector
        title={trans("__border")}
        propertyName="stroke"
        target={activeCanvasObject}
      />
      <div className={styles.division_line} />
      <ObjectColorSelector
        title={trans("__backgroundColor")}
        propertyName="backgroundColor"
        target={activeCanvasObject}
      />
      <div className={styles.division_line} />
      <LineSelector target={activeCanvasObject} />
      <div className={styles.division_line} />
      <OpacityTools target={activeCanvasObject} />
      <div className={styles.division_line} />
      <ShadowTools target={activeCanvasObject} />
      <div className={styles.division_line} />
      <AngleSelector target={activeCanvasObject} />
      <div className={styles.division_line} />
      <LevelTools target={activeCanvasObject} />
      <div className={styles.division_line} />
      <FlipTools target={activeCanvasObject} />
      {activeCanvasObject.type === 'activeSelection' && <AlignTools />}
    </>
  );
};

export default ObjectStylePanel;
