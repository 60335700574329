import i18n from 'i18next';

const trans = (
    key,
    defaultValue,
    params,
) => {
  return i18n.t( "constants.gaEventType." + key, {...params, defaultValue}) || '';
};

export const CategoryType = {
  InteractiveObject: 'InteractiveObject',
  ReaderTool: 'ReaderTool',
  OnePass: "OnePass",
  SyncAnnotation: 'SyncAnnotation',
  NextPrevPage: 'NextPrevPage',
}

export const CategoryTypeWording = {
  [CategoryType.InteractiveObject]: trans("__interactiveObject"),
  [CategoryType.ReaderTool]: trans("__toolbar"),
  [CategoryType.OnePass]: 'OnePass',
  [CategoryType.SyncAnnotation]: trans("__syncRecord"),
  [CategoryType.NextPrevPage]: trans("__previousNextPage"),
}