import React from 'react';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import {useTranslation} from "react-i18next";

const ShadowTools = ({target: object}) => {

    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.shadowTools.${key}`)
    };
    const shadowChangeHandler = e => {
        EventBus.emit({ event: CanvasEvent.SetCanvasShadowEvent ,
            payload:{ object, isShowShadow:e.target.checked  }
        });
    }

    return (
        <div className={styles.shadowTools}>
            {trans("__shadow")}
             <input type="checkbox" onChange={shadowChangeHandler} checked={object.shadow}/>
        </div>
    )
}


export default ShadowTools;