export const LANGUAGES = {
    VIETNAMESE: 'vi-VN',
    ENGLISH: 'en',
    TRADITIONAL_CHINESE: 'zh-TW',
    // THAI: 'th-TH',
};

export const LANGUAGES_DISPLAY = {
  [LANGUAGES.VIETNAMESE]: '🇻🇳 Tiếng Việt',
    [LANGUAGES.ENGLISH]: '🇺🇸 English',
    [LANGUAGES.TRADITIONAL_CHINESE]: '🇹🇼 中文',
    // [LANGUAGES.THAI]: '🇹🇭 ไทย',
};
