import React, { useState, useEffect, useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import styles from './index.module.scss';
import { QRCode } from 'react-qr-svg';
import classnames from 'classnames';
import { useTranslation } from "react-i18next";
import { Roles } from 'constants/role';
import { EventBus } from 'events/EventBus';
import { ActivityEvent } from 'events/EventTypes';
import * as types from 'constants/actionTypes';
import { useMonitorActivityAttendeeList } from 'customHooks/activity';

const ActivityPanel = ({ paramsActivityId, paramsTeacherId }) => {
    const [{ annotationId: activityId, activityInfo: { activityTarget, isActivityPanelShow, seats, ownerId } }, annotationDispatch] = useStore(StoreTypes.annotation);
    const [{ role }] = useStore(StoreTypes.user);
    const [guestUrl, setGuestUrl] = useState('');
    const { monitorActivityAttendeeList, unsubscribeMonitorActivityAttendeeList } = useMonitorActivityAttendeeList();
    const { i18n,t } = useTranslation();
    const trans = (key) => {
        return t(`components.activity.activityPanel.${key}`)
    };
    useEffect(() => {
        monitorActivityAttendeeList({ activityId: activityId || paramsActivityId, teacherId: ownerId || paramsTeacherId });
        return () => unsubscribeMonitorActivityAttendeeList();
    }, [activityId, monitorActivityAttendeeList, ownerId, paramsActivityId, paramsTeacherId, unsubscribeMonitorActivityAttendeeList]);

    useEffect(() => {
        const guestURL = `${encodeURI(window.location.href.split('?')[0])}?role=STUDENT&activityId=${activityId}&teacherId=${ownerId}`;
        setGuestUrl(guestURL);
        // console.log('-----qrcode url------')
        // console.log(guestURL, isActivityPanelShow)
    }, [activityId, annotationDispatch, isActivityPanelShow, ownerId]);

    const setClick = useCallback((attendeeNumber, join) => {
        if (role === Roles.TEACHER) {
            if (join && activityTarget !== attendeeNumber) {
                EventBus.emit({
                    event: ActivityEvent.ChangeActivityTargetEvent,
                    payload: { attendeeNumber }
                });
            }
        } else if (role === Roles.STUDENT) {
            if (join) { return }
            EventBus.emit({
                event: ActivityEvent.JoinActivityEvent,
                payload: {
                    attendeeNumber,
                    activityId: activityId || paramsActivityId,
                    teacherId: paramsTeacherId,
                    callback: () => {
                        annotationDispatch({ type: types.TOGGLE_ACTIVITY_PANEL, enable: false });
                    }
                }
            });
        }
    }, [activityId, activityTarget, annotationDispatch, paramsActivityId, paramsTeacherId, role]);

    //篩選為學生就不出現老師座位，為老師就全部出現並將admin文字顯示為老師
    const setSeats = () => {
        return seats.map((obj, i) => {
            const isAdmin = obj.attendee === "admin";
            let displaySeat = (role === Roles.TEACHER || !isAdmin);
            return displaySeat && <div
                key={i}
                className={
                    ((activityTarget === obj.id) ? styles['selected-seat'] : (obj.join ? styles.onset : styles.offset))
                }
                onClick={() => {
                    setClick(obj.id, obj.join)
                }}
            >
                {isAdmin ? trans("__teacher") : obj.attendee}
            </div>
        })
    }

    return (
        <div
            id='activityPanel'
            className={classnames(styles.activityPanel, styles[isActivityPanelShow ? 'enable' : 'disable'])}
        >
            <div
                id='activityPanelBG'
                className={classnames(styles.contentBG, styles[role === Roles.TEACHER ? 'enable' : 'disable'])}
                onClick={(e) => {
                    if (role === Roles.TEACHER) {
                        EventBus.emit({
                            event: ActivityEvent.ToggleActivityPanelEvent,
                            payload: { enable: false }
                        });
                    }
                }}
            >
            </div>
            <div
                className={classnames(styles.content, styles[role === Roles.TEACHER ? 'browser' : 'mobile'])}
            >
                <div className={classnames(styles.qrCodeArea, styles[role === Roles.TEACHER ? 'enable' : 'disable'])}>
                    {role && guestUrl && <QRCode
                        id='QR'
                        className={styles.qrCode}
                        level='Q'
                        value={guestUrl}
                    />}
                </div>
                <div className={classnames(styles.setArea, styles[role === Roles.TEACHER ? 'half' : 'full'])}>
                    {
                        setSeats()
                    }
                </div>
            </div>
        </div>
    );
};

export default ActivityPanel;
