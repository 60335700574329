import React, { useCallback } from 'react';
import Icon from 'components/Icon';
import ControlButton from 'components/common/Player/ControlButton';
import useSetState from 'customHooks/setState';
import { SubtitlesPositionType, SubtitlesFontSizeType } from 'constants/videoSettingType';
import classnames from 'classnames';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";



const Panel = ({
  isShow = false,
  playbackRateValue = 1,
  subtitlesFontSizeValue = SubtitlesFontSizeType.MEDIUM,
  onPlaybackRateChange = () => { },
  onSubtitlesFontSizeChange = () => { },
  isShowSubtitleSetting,
  subtitlesPositionValue = subtitlesPosition.DOWN,
  onSubtitlesPositionChange = () => { },
  onOpenDefaultVideo = () => { },
}) => {

  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.common.videoPlayer.settingPanelControl.${key}`)
  };
  const PlaybackRateList = [
    { label: trans("__slow"), value: 0.75 },
    { label: trans("__normal"), value: 1 },
    { label: trans("__fast"), value: 1.25 }
  ];

  const subtitlesFontSize = [
    { label: trans("__large"), value: SubtitlesFontSizeType.LARGE },
    { label: trans("__medium"), value: SubtitlesFontSizeType.MEDIUM },
    { label: trans("__small"), value: SubtitlesFontSizeType.SMALL }
  ];

  const subtitlesPosition = [
    { label: trans("__up"), value: SubtitlesPositionType.UP },
    { label: trans("__down"), value: SubtitlesPositionType.DOWN }
  ];

  return (
      <div className={classnames(styles.panel, {
        [styles.active]: isShow
      })}>
        <div className={styles.main}>
          <div className={styles.list}>
            <div className={styles.title}>{trans("__playbackSpeed")}</div>
            {
              PlaybackRateList.map(item => (
                  <div
                      key={item.value}
                      className={classnames(styles.listItem, {
                            [styles.active]: item.value === playbackRateValue
                          }
                      )}
                      onClick={() => onPlaybackRateChange(item.value)}
                  >
                    <span className={styles.label}>{item.label}</span>
                  </div>
              ))
            }
          </div>
          {
              isShowSubtitleSetting && (
                  <>
                    <div className={styles.list}>
                      <div className={styles.title}>{trans("__fontSize")}</div>
                      {
                        subtitlesFontSize.map(item => (
                            <div
                                key={item.value}
                                className={classnames(styles.listItem, {
                                      [styles.active]: item.value === subtitlesFontSizeValue
                                    }
                                )}
                                onClick={() => onSubtitlesFontSizeChange(item.value)}
                            >
                              <span className={styles.label}>{item.label}</span>
                            </div>
                        ))
                      }
                    </div>
                    <div className={styles.list}>
                      <div className={styles.title}>{trans("__subtitlePosition")}</div>
                      {
                        subtitlesPosition.map(item => (
                            <div
                                key={item.value}
                                className={classnames(styles.listItem, {
                                      [styles.active]: item.value === subtitlesPositionValue
                                    }
                                )}
                                onClick={() => onSubtitlesPositionChange(item.value)}
                            >
                              <span className={styles.label}>{item.label}</span>
                            </div>
                        ))
                      }
                    </div>
                  </>
              )
          }
        </div>
        {
            window.electron &&
            <>
              <div className={styles.hr} />
              <div className={styles.openVideo} onClick={onOpenDefaultVideo}>
                <Icon name="OpenVideo" />
                {trans("__openVideoOnComputer")}
              </div>
            </>
        }
      </div>
  );
}


const SettingPanelControl = ({
  playbackRateValue,
  subtitlesFontSizeValue,
  onSubtitlesFontSizeChange,
  onPlaybackRateChange,
  isShowSubtitleSetting,
  subtitlesPositionValue,
  onSubtitlesPositionChange,
  onOpenDefaultVideo
}) => {

  const [{ isShowPanel }, setState] = useSetState({
    isShowPanel: false
  });

  const togglePanelShow = useCallback(() => setState({ isShowPanel: !isShowPanel }), [isShowPanel, setState])

  return (
    <ControlButton
      active={isShowPanel}
      className={styles.settingPanelControl}
      onClick={() => togglePanelShow()}
    >
      <Icon type="text" name="cog" />
      <Panel
        isShow={isShowPanel}
        playbackRateValue={playbackRateValue}
        subtitlesFontSizeValue={subtitlesFontSizeValue}
        onSubtitlesFontSizeChange={onSubtitlesFontSizeChange}
        onPlaybackRateChange={onPlaybackRateChange}
        isShowSubtitleSetting={isShowSubtitleSetting}
        subtitlesPositionValue={subtitlesPositionValue}
        onSubtitlesPositionChange={onSubtitlesPositionChange}
        onOpenDefaultVideo={onOpenDefaultVideo}
      />
    </ControlButton>
  )
};

export default SettingPanelControl;
