import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import {useTranslation} from "react-i18next";

const TranslationControlsButton = ({className, active, content, onClick}) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.common.translationControlsButton.${key}`)
    };
    return (
        <div
            className={classnames(styles.button, className, {
                [styles.active]: active
            })}
            onClick={onClick}
        >
            <div>{content}</div>
            <div>{active ? trans("__hide") : trans("__display")}</div>
        </div>
    )
}

export default TranslationControlsButton;
