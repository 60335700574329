import React from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";

const Footer = () => {
  const menus = [
    {
      title: '歡迎光臨',
      items: [
        {
          text: 'onemall 閱讀器',
          link: ''
        },
        {
          text: '全站活動',
          link: ''
        },
        {
          text: '下載APP',
          link: ''
        },
        {
          text: '常見問題 Q&A',
          link: ''
        },
        {
          text: '我要買書',
          link: ''
        },
        {
          text: '意見回報',
          link: ''
        }
      ]
    },
    {
      title: '關於我們',
      items: [
        {
          text: '公司資訊',
          link: ''
        },
        {
          text: '徵人啟事',
          link: ''
        },
        {
          text: '合作提案',
          link: ''
        },
        {
          text: 'AP推書夥伴',
          link: ''
        }
      ]
    },
    {
      title: '服務條款',
      items: [
        {
          text: '服務條款',
          link: ''
        },
        {
          text: '隱私權條款',
          link: ''
        }
      ]
    },
    {
      title: '社群連結',
      items: [
        {
          text: 'Fackbook',
          link: ''
        },
        {
          text: 'Line',
          link: ''
        }
      ]
    }
  ];

  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.footer.${key}`)
  };
  const dialogCopyright = () => {
    alert(trans("__dialogCopyright"))
  }

  return (
    <footer>
      <div className={styles.container}>
        {/* <div className={styles.top}>
                    {
                        menus.map((list, index) => {
                            return (
                                <div key={index} className={styles.list_box}>
                                    <h2>{list.title}</h2>
                                    <ul className={styles.list_sub_box}>
                                        {list.items.map((list, i) => <li key={i} className={styles.list}>{list.text}</li>)}
                                    </ul>
                                </div>
                            );
                        })
                    }
                </div> */}
        <div className={styles.connectionInfo}>
          <div>
            <div>
              <h2 className={styles.connectionUs}>{trans("__contactUs")}</h2>
              <hr className={styles.connectionInfoHR} />
            </div>
          </div>
          <div className={styles.connectionInfoButtons}>
            <div></div>
            <div>
              <a
              // href="https://s.nani.cool/ebookforms"
              href="#"
              target='_blank' rel='noopener noreferrer'>
                <div className="mb-3 text-muted">
                  <Icon type="text" name="envelope" />
                </div>
                <div>{trans("__feedback")}</div>
              </a>
            </div>
            <div>
              <a
              // href="https://cdn.nani.cool/download/%E5%8D%97%E4%B8%80%E9%81%A0%E7%AB%AF%E9%80%A3%E7%B7%9A.exe"
              href="#"
              // download
              >
                <div className="mb-3 text-muted">
                  <Icon type="text" name="tools" />
                </div>
                <div>{trans("__remoteConnection")}</div>
              </a>
            </div>
            <div></div>
          </div>
          <div className={styles.command}>
            {trans("__bestBrowserExperience")}：IE Edge、Google
            Chrome {trans("__or")} Firefox。
            <br />{' '}
            {trans("__ieNotice")}
          </div>
        </div>
        <div className={styles.bottom}>
          <div>{trans("__naniEducation")} Copyright ©</div>
          <div className={styles.copyrightBtn} onClick={() => dialogCopyright()}>{trans("__copyrightNotice")}</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
