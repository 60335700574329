import React from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";


export const WordExampleComponent = ({ object, saveProperty }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.propertyForm.wordExamplePropertyForm.${key}`)
    };


    let wordExampleInfo = object.wordExampleInfo ? JSON.parse(object.wordExampleInfo) : { src: '', word: '' }

  const changeHandler = (infoType, value) => {
    wordExampleInfo[infoType] = value;
    saveProperty(JSON.stringify(wordExampleInfo))
  };

  return (
    <>
      <label>
        <input type="checkbox" onChange={e => changeHandler('isZhuyin', e.target.checked)} checked={wordExampleInfo.isZhuyin} />
          {trans("__label")}
      </label>
      <Input className={styles.wordExampleInput} label="Src" value={wordExampleInfo.src} onChange={e => changeHandler('src', e.target.value)} />
      <Input className={styles.wordExampleInput} label="Word" value={wordExampleInfo.word} onChange={e => changeHandler('word', e.target.value)} />
    </>
  )
};