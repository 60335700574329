import React from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";


export const WordGridComponent = ({ object, saveProperty }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.propertyForm.wordGridPropertyForm.${key}`)
    };


    let wordGridInfo = object.wordGridInfo ? JSON.parse(object.wordGridInfo) : { src: '', word: '', wordsSetSrc: '', idiomSetSrc: '' }

  const changeHandler = infoType => e => {
    wordGridInfo[infoType] = e.target.value;
    saveProperty(JSON.stringify(wordGridInfo))
  };

  return (
    <>
      <Input className={styles.wordGridInput} label="Src" value={wordGridInfo.src} onChange={changeHandler('src')} />
      <Input className={styles.wordGridInput} label="Word" value={wordGridInfo.word} onChange={changeHandler('word')} />
      <br />
      <label htmlFor="defaultOpacity">{trans("__extendedTeaching")}</label>
      <Input className={styles.wordGridInput} label={trans("__vocabularyList")} value={wordGridInfo.wordsSetSrc} onChange={changeHandler('wordsSetSrc')} />
      <Input className={styles.wordGridInput} label={trans("__idiomList")} value={wordGridInfo.idiomSetSrc} onChange={changeHandler('idiomSetSrc')} />
    </>
  )
};
