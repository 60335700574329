import React from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import Draggable from 'react-draggable';
import oneLinkSuccess from 'components/PaiQuiz/oneLinkSuccess.png';
import {useTranslation} from "react-i18next";

const BoxModal = ({ title, content, bottom, onCloseButtonClick, goBack, needConfirmClose, goToOneLink, goToOneLinkLink }) => {
  const { t } = useTranslation();

  const paiQuizContent = () => {
    const trans = (key) => {
      return t(`components.paiQuiz.boxModal.${key}`)
    };

    if (needConfirmClose) {
      return (<div className={styles.boxModal}>
        <div className={styles.controlHead}>
          <h1>{trans("__confirmClose")}？</h1>
        </div>
        <p className={styles.confirmDialog}>{trans("__recommendPreview")}<span>{trans("__answerRecord")}</span>{trans("__andSave")}，<br />
          {trans("__closingWarning")}</p>
        <div className={styles.bottom}>
          <button type="button" className={styles.confirmClose} onClick={onCloseButtonClick}>{trans("__confirmCloseWindow")}</button>
          <button type="button" className={styles.goBack} onClick={goBack}>{trans("__goBack")}</button>
        </div>
      </div>)
    } else if (goToOneLink) {
      return (<div className={styles.boxModal}>
        <div className={styles.controlHead}>
          <div className={styles.closeButton} onClick={onCloseButtonClick}>
            <Icon type="text" name="times" />
          </div>
        </div>
        <h1 className={styles.oneLinkTitle}>
          <img src={oneLinkSuccess} alt={trans("__success")} />
          {trans("__deliverySuccess")}</h1>
        <p className={styles.oneLinkDialog}>{trans("__reviewTest")}</p>
        <div className={styles.bottom}>
          {
            window.electron ?
            (<button className={`${styles.oneLinkLink} ${styles.forElectron}`} onClick={() => {
              window.electron && window.electron.ipcRenderer.send("openExternal", goToOneLinkLink);
            }}>{trans("__viewAnswerRecord")}  ❯</button>) : (
              <a href={goToOneLinkLink} className={styles.oneLinkLink} target="_blank" rel="noopener noreferrer">{trans("__viewAnswerHistory")}  ❯</a>
            )
          }
        </div>
      </div>)
    } else {
      return (<div className={styles.boxModal}>
        <div className={styles.controlHead}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.closeButton} onClick={onCloseButtonClick}>
            <Icon type="text" name="times" />
          </div>
        </div>
        <div className={styles.content}>
          {content}
        </div>
        <div className={styles.bottom}>
          {bottom}
        </div>
      </div>)
    }
  }
  return (
    <div className={styles.paiquizMask}>
      <Draggable
        handle={`.${styles.controlHead}`}
        positionOffset={{ x: '-50%', y: '-50%' }}
      >
        {paiQuizContent()}
      </Draggable>
    </div>
  )
}


export default BoxModal;