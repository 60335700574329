import React, { useCallback, useEffect } from 'react';
import { CanvasLockType } from 'constants/lockTypes';
import Input from 'components/common/Input';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import useSetState from 'customHooks/setState';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";

const LockTools = ({ target: object }) => {
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.tools.lockTools.${key}`)
  };

  const [{ lockMovementX, lockMovementY, hasControls }, setState] = useSetState(
    {
      lockMovementX: object[CanvasLockType.LockMovementX],
      lockMovementY: object[CanvasLockType.LockMovementY],
      hasControls: object[CanvasLockType.HasControls]
    }
  );

  const setLock = useCallback(
    (property, value) => {
      EventBus.emit({
        event: CanvasEvent.LockCanvasObjectEvent,
        payload: { object, property, value }
      });
    },
    [object]
  );

  useEffect(() => {
    const { lockMovementX, lockMovementY, hasControls } = object;
    setState({
      lockMovementX,
      lockMovementY,
      hasControls
    });
  }, [
    object,
    object.lockMovementX,
    object.lockMovementY,
    object.hasControls,
    setState
  ]);

  return (
    <div className={styles.lockTools}>
      <h3>{trans("__lock")}</h3>
      <div className={styles.lockToolsInput}>
        <Input
          className={styles.checkbox}
          type="checkbox"
          label={trans("__zoom")}
          checked={!hasControls ? 'checked' : ''}
          onChange={e => {
            setLock(CanvasLockType.HasControls, !e.target.checked);
          }}
        />
        <Input
          className={styles.checkbox}
          type="checkbox"
          label={trans("__horizontal")}
          checked={object[CanvasLockType.LockMovementX] ? 'checked' : ''}
          onChange={e =>
            setLock(CanvasLockType.LockMovementX, e.target.checked)
          }
        />
        <Input
          className={styles.checkbox}
          type="checkbox"
          label={trans("__vertical")}
          checked={lockMovementY ? 'checked' : ''}
          onChange={e =>
            setLock(CanvasLockType.LockMovementY, e.target.checked)
          }
        />
        <Input
          className={styles.checkbox}
          type="checkbox"
          label={trans("__selectAll")}
          checked={
            lockMovementX && lockMovementY && !hasControls ? 'checked' : ''
          }
          onChange={e => {
            setLock(CanvasLockType.LockMovementX, e.target.checked);
            setLock(CanvasLockType.LockMovementY, e.target.checked);
            setLock(CanvasLockType.HasControls, !e.target.checked);
          }}
        />
      </div>
    </div>
  );
};

export default LockTools;
