import React, { useEffect, useState } from 'react';
import Icon from 'components/Icon';
import classnames from 'classnames';
import useSetState from 'customHooks/setState';
import { ToolboxCategory } from 'constants/ReaderTools'
import styles from './index.module.scss';
import { useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context'
import Repository from 'repositories/Repository';
import {useTranslation} from "react-i18next";

const { ReaderToolBarRepository, ExtendedResourceRepository } = Repository;

const Toolbox = ({
  children,
  toolboxType,
  onChange = () => {},
  onResetClick = () => {}
}) => {
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.toolbox.${key}`)
  };

  const TABS_MAP = {
    [ToolboxCategory.Settings]: { type: ToolboxCategory.Settings, label: trans("__settingsTool") },
    [ToolboxCategory.Controls]: { type: ToolboxCategory.Controls, label: trans("__controlTool") },
    [ToolboxCategory.EditTools]: { type: ToolboxCategory.EditTools, label: trans("__editTool") },
    [ToolboxCategory.TeachingTools]: { type: ToolboxCategory.TeachingTools, label: trans("__teachingTool") },
    [ToolboxCategory.MathTools]: { type: ToolboxCategory.MathTools, label: trans("__mathTool") },
    [ToolboxCategory.OfflineTools]: { type: ToolboxCategory.OfflineTools, label: trans("__offlineTool") },
  }


  const [{ modal:{ isShowing:isModalShowing } }] = useStore(StoreTypes.global);
  const [{ bookId }] = useStore(StoreTypes.books);
  const [tabs,setTabs] = useState([]);
  const [tools,setTools] = useState([]);

  const [{ activeTabIndex, currentType },setState] = useSetState({
    activeTabIndex: 0,
    currentType: ToolboxCategory.Settings
  });


  useEffect(()=>{
    const toolboxCategory = ReaderToolBarRepository.getReaderToolboxCategory();
    setTabs(toolboxCategory.map(item=> TABS_MAP[item]))
  },[])

  useEffect(()=>{
    const index = tabs.findIndex(item => item.type === toolboxType);
    setState({ activeTabIndex: index, currentType: toolboxType })
  },[setState, tabs, toolboxType])

  useEffect(() => {
    bookId && (async () => {
        const tools = await ExtendedResourceRepository.getToolbarTools({ bookId }) || [];
        setTools(tools.filter(item=>item.type && item.type === 'offlineTool'));
    })();
}, [bookId]);

  const tabClickHandler = index => {
    setState({ activeTabIndex: index, currentType: tabs[index].type })
    onChange(tabs[index].type);
  }

  const closeHandler = useCallback(()=>{
    onChange(tabs[0].type);
    EventBus.emit({
      event: ReaderToolsEvent.ClickReaderToolBoxEvent,
      payload: {
        isToolboxShow: false
      }
    })
  },[onChange, tabs])

  useEffect(()=>{
    isModalShowing && closeHandler();
  },[closeHandler, isModalShowing])

  return  (
    <div className={styles.toolboxContainer}>
      <div className={styles.toolbox}>
          <div className={styles.closeButton} onClick={closeHandler}>
            <Icon type="text" name="times" />
          </div>
          {
            currentType === ToolboxCategory.OfflineTools && tools.length > 0 ? (
              <div className={styles.toolsList}>
                {
                  tools.map(tool => (
                    <div key={tool.name} className={styles.btn} onClick={() => ExtendedResourceRepository.postTools({ bookId, filename: tool.filename })}>
                      <div className={styles.text}>{tool.name}</div>
                    </div>
                  ))
                }
              </div>
            ) : (
              <div className={styles.toolsList}>
                {children}
              </div>
            )
          }
          <div className={styles.settingControls}>
            <div className={styles.tabContainer}>
              {
                tabs.map((item,index)=> (
                  <div
                    key={item.label}
                    className={classnames(styles.tab,{ [styles.active]: index ===  activeTabIndex })}
                    onClick={()=> tabClickHandler(index)}
                  >{item.label}</div>
                ))
              }
            </div>
            <div className={styles.resetButton} onClick={onResetClick}>
              {trans("__reset")}
            </div>
          </div>
      </div>
    </div>
  )
};


export default Toolbox;