import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import i18n from 'i18next';

const t = (
    key,
    defaultValue,
    params,
) => {
  return i18n.t( "constants.contentType." +key, {...params, defaultValue}) || '';
};


export const ContentTypeDisplayMap = {
  [InteractiveObjectContentType.ExternalWindow]: t("__externalLink"),
  [InteractiveObjectContentType.InternalWindow]: t("__internalWindow"),
  [InteractiveObjectContentType.ToggleDisplay]: t("__coverAnswer"),
  [InteractiveObjectContentType.ControlDisplay]: t("__fullSolution"),
  [InteractiveObjectContentType.Stage]: t("__stepNumbering"),
  [InteractiveObjectContentType.ControlStage]: t("__stepSolution"),
  [InteractiveObjectContentType.Image]: t("__externalLink"),
  [InteractiveObjectContentType.Image]: t("__image"),
  [InteractiveObjectContentType.ControlImage]: t("__changeImage"),
  [InteractiveObjectContentType.GoPage]: t("__jumpPage"),
  [InteractiveObjectContentType.Video]: t("__video"),
  [InteractiveObjectContentType.Audio]: t("__audio"),
  [InteractiveObjectContentType.AreaZoom]: t("__zoomInArea"),
  [InteractiveObjectContentType.InternalHtmlWindow]: t("__htmlInternalWindow"),
  [InteractiveObjectContentType.Vocabulary]: t("__vocabularySentences"),
  [InteractiveObjectContentType.Translation]: t("__translationPopup"),
  [InteractiveObjectContentType.WordGrid]: t("__vocabularyDatabase"),
  [InteractiveObjectContentType.VolumeOneWords]: t("__firstBookVocabularyDatabase"),
  [InteractiveObjectContentType.Submenu]: t("__subMenu"),
  [InteractiveObjectContentType.WordExample]: t("__vocabularyMenu"),
  [InteractiveObjectContentType.Synopsis]: t("__multipleChoicePopup"),
  [InteractiveObjectContentType.Hearing]: t("__listeningScript"),
  [InteractiveObjectContentType.FlashCard]: 'Flash Card',
  [InteractiveObjectContentType.Listening]: t("__listenToStory"),
  [InteractiveObjectContentType.WordGlyph]: t("__pronunciationAndForm"),
  [InteractiveObjectContentType.WordMeaning]: t("__meaningTeaching"),
  [InteractiveObjectContentType.AboutTheAuthor]: t("__authorIntroduction"),
  [InteractiveObjectContentType.ReadAloud]: t("__readAloud"),
  [InteractiveObjectContentType.ZhuyinLesson]: t("__bopomofoText"),
  [InteractiveObjectContentType.ZhuyinImage]: t("__bopomofoImage"),
  [InteractiveObjectContentType.ZhuyinWord]: t("__oneSoundTwoWords"),
  [InteractiveObjectContentType.MathTools]: t("__rulerDrawing"),
  [InteractiveObjectContentType.MeasureTools]: t("__measuringTools"),
  [InteractiveObjectContentType.PaiQuiz]: t("__sendQuiz"),
  [InteractiveObjectContentType.PageWidth]: t("__pageWidth"),
};

export const ReaderToolTypeDisplayMap = {
  [ReaderToolType.Expression]: t("__emoji"),
  [ReaderToolType.SynchronousCamera]: t("__remoteControl"),
  [ReaderToolType.BackToCatalog]: t("__backToCatalog"),
  [ReaderToolType.StickyNote]: t("__stickyNote"),
  [ReaderToolType.Link]: t("__link"),
  [ReaderToolType.MathTools]: t("__rulerDrawing"),
  [ReaderToolType.Bookshelf]: t("__bookshelf"),
  [ReaderToolType.Menu]: t("__menu"),
  [ReaderToolType.Mark]: t("__mark"),
  [ReaderToolType.Drag]: t("__drag"),
  [ReaderToolType.Select]: t("__select"),
  [ReaderToolType.Painting]: t("__brush"),
  [ReaderToolType.Highlighter]: t("__highlighter"),
  [ReaderToolType.Shape]: t("__shape"),
  [ReaderToolType.Line]: t("__lineSegment"),
  [ReaderToolType.DeleteAll]: t("__deleteAll"),
  [ReaderToolType.Delete]: t("__deletePartial"),
  [ReaderToolType.ArrowLeft]: t("__flipLeft"),
  [ReaderToolType.ArrowRight]: t("__flipRight"),
  [ReaderToolType.ViewPrevPage]: t("__bookEdgePreviousPage"),
  [ReaderToolType.ViewNextPage]: t("__bookEdgeNextPage"),
  [ReaderToolType.PageSearcherPrevPage]: t("__pageNumberSelectPrevious"),
  [ReaderToolType.PageSearcherNextPage]: t("__pageNumberSelectNext"),
  [ReaderToolType.PageSearcher]: t("__pageNumberSelect"),
  [ReaderToolType.FourZoomIn]: t("__zoomInFourGrid"),
  [ReaderToolType.AllScreen]: t("__originalSize"),
  [ReaderToolType.IntervalZoomIn]: t("__areaZoomIn"),
  [ReaderToolType.FullScreen]: t("__fullScreen"),
  [ReaderToolType.Whiteboard]: t("__whiteboard"),
  [ReaderToolType.NumberPicker]: t("__numberSelector"),
  [ReaderToolType.Img]: t("__addImage"),
  [ReaderToolType.Text]: t("__addText"),
  [ReaderToolType.SVG]: t("__svgObject"),
  [ReaderToolType.PageMode]: t("__singleDoublePage"),
  [ReaderToolType.ReaderOption]: t("__settings"),
  [ReaderToolType.Bookmark]: t("__tab"),
  [ReaderToolType.Activity]: t("__activity"),
  [ReaderToolType.ExportInteractiveObject]: t("__export"),
  [ReaderToolType.AttendeeNumber]: t("__seatNumber"),
  [ReaderToolType.OfflineReaderToolBox]: t("__toolbox"),
  [ReaderToolType.Toolbox]: t("__toolbox"),
  [ReaderToolType.Timer]: t("__timer"),
  [ReaderToolType.Stopwatch]: t("__stopwatch"),
  [ReaderToolType.MeasureTools]: t("__measurementTools"),
  [ReaderToolType.Protractor]: t("__protractorAgain"),
  [ReaderToolType.ShortRuler]: t("__shortRuler"),
  [ReaderToolType.LongRuler]: t("__longRulerAgain"),
  [ReaderToolType.IsoscelesTriangle]: t("__isoscelesTriangleAgain"),
  [ReaderToolType.RightTriangle]: t("__rightTriangleAgain"),
  [ReaderToolType.StampCollection]: t("__buttonCollapseAgain"),
  // [ReaderToolType.SyncAnnotation]: '儲存雲端'
  [ReaderToolType.PrevPage]: t("__previousPageAgain"),
  [ReaderToolType.NextPage]: t("__nextPageAgain")
};

export const SyncAnnotationType = {
  web: t("__webSyncRecord"),
  electron: t("__offlineSyncRecord"),
  mobile: t("__mobileSyncRecord"),
};

export const QrcodeLoginType ={
  accept: t("__receive"),
  sent: t("__submit"),
}