import { useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';


export const usePageWidthCommand = () => {
  const [{ fullWidthInfo: { mode: fullWidthMode } }] = useStore(StoreTypes.reader);

  const execute = useCallback(({ json: { contentType } }) => {
    console.log('contentType', contentType, fullWidthMode)
    if (fullWidthMode) {
      EventBus.emit({
        event: ReaderToolsEvent.ClickFullWidthEvent,
        payload: { fullWidthMode: false },
      });
    } else if (!fullWidthMode) {
      EventBus.emit({
        event: ReaderToolsEvent.ClickFullWidthEvent,
        payload: { fullWidthMode: true },
      });
    } else {
      console.log('Something wrong with usePageWidthCommand');
    }
  }, [fullWidthMode]);

  return { execute };
};
