/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from "react";
import Input from "components/common/Input";
import styles from "./index.module.scss";
import { sendData } from "./login";
import { useStore, StoreTypes } from "context";
import { LOGIN_POPUP } from "constants/loginTypes";
import { EventBus } from "events/EventBus";
import { UserEvent, ReaderToolsEvent } from "events/EventTypes";
import useSetState from "customHooks/setState";
import { getMoeLoiginUrl } from "./moe";
import { QRCodeSVG } from "qrcode.react";
import { short_uuid } from "../../../util/uuid";
import { useLocation } from "react-router-dom";
import { gaEventQrcodeLogin } from "components/AppService/GAService";
import { CategoryType } from "constants/gaEventType";
import { QrcodeLoginType } from "constants/contentType";
import {useTranslation} from "react-i18next";

const qrcodeGetTicketApi = async ({ id }) => {
  let ticketRes = "";
  const data = { id, hostId: localStorage.getItem("hostId") };
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_DOMAIN}/qrcode/getTicket`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(data),
      }
    );
    ticketRes = await res.json();
    return ticketRes;
  } catch (error) {
    console.error(error);
  }
  return ticketRes;
};


const qrcodeAddTokenApi = async ({ id, jwt }) => {
  let addTokenRes = "";
  const data = { id: id };
  try {
    const res = await fetch(`${process.env.REACT_APP_API_DOMAIN}/qrcode/add`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: jwt,
      }),
      body: JSON.stringify(data),
    });
    addTokenRes = await res.json();
    gaEventQrcodeLogin({
      category: CategoryType.OnePass,
      action: QrcodeLoginType.sent,
      label: "使用者掃描後送出登入要求",
    });
    return addTokenRes;
  } catch (error) {
    console.error(error);
  }
  return addTokenRes;
};

const CountDownTimer = (props) => {
  const [remainTime, setRemainTime] = useState(0);
  const [currentId, setCurrentId] = useState(short_uuid());
  const [{ popupState }, loginDispatch] = useStore(StoreTypes.login);

  const { t } = useTranslation();
  const trans = useCallback((key) => {
    return t(`components.login.popup.${key}`)
  });
  const qrcodeGetTokenApi = async ({ id, ticketId }) => {
    let tokenRes = "";
    const data = { id, ticketId };
    try {
      const res = await fetch(
          `${process.env.REACT_APP_API_DOMAIN}/qrcode/getToken`,
          {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify(data),
          }
      );
      tokenRes = await res.json();
      gaEventQrcodeLogin({
        category: CategoryType.OnePass,
        action: QrcodeLoginType.accept,
        label: trans("__userLoginSuccess"),
      });
      return tokenRes;
    } catch (error) {
      console.error(error);
    }
    return tokenRes;
  };

  useEffect(() => {
    if (popupState === false) {
      setRemainTime(0);
    } else {
      setRemainTime(300);
      setCurrentId(short_uuid());
    }
  }, [popupState]);
  useEffect(() => {
    const qrcodeLogin = async () => {
      const ticketRes = await qrcodeGetTicketApi({ id: currentId });
      if (ticketRes.status === "success") {
        const tokenRes = await qrcodeGetTokenApi({
          id: currentId,
          ticketId: ticketRes.data,
        });
        const today = Math.trunc((new Date().valueOf())/1000)
        const jwt = require('jsonwebtoken');
        const tokenExp = jwt.decode(tokenRes.data).exp
        if (!(tokenExp > today)){
          props.onChangeErrorMessage(trans("__crossDeviceLogin"))
          return
        }
        const nani_tokenInfo = { code: "SUCCESS", jwt: tokenRes.data };
        localStorage.setItem("nani_tokenInfo", JSON.stringify(nani_tokenInfo));
        document.cookie =
          "nani_oneclass_login_token=" + JSON.stringify(nani_tokenInfo) + ';path=/;domain=oneclass.vn';
        EventBus.emit({
          event: UserEvent.hardLoginEvent,
          payload: { token: tokenRes.data },
        });
        loginDispatch({ type: LOGIN_POPUP, popupState: false });
      }
    };
    const timer = window.setInterval(() => {
      if (remainTime > 0) {
        setRemainTime((prevCount) => {
          return prevCount - 1;
        });
        qrcodeLogin();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [remainTime, currentId, props, loginDispatch, qrcodeGetTokenApi, trans]);
  const minute = Math.trunc(remainTime / 60);
  const second = remainTime % 60 < 10 ? `0${remainTime % 60}` : remainTime % 60;

  return (
    <>
      <QRCodeSVG
        value={
          remainTime > 0
            ? `${process.env.REACT_APP_READER_DOMAIN}/qrcodelogin?uuid=${currentId}`
            : ""
        }
        className={styles.qrcode}
      />
      <div className={styles.CountDownTimer}>
        <p className={styles.timeLimit}>
          {remainTime === 0 ? (
            <span style={{ color: "#EC7963" }}>QR Code {trans("__expiredReload")}</span>
          ) : (
            <>
              QR Code {trans("__validityPeriod")}：
              <span style={{ color: "#EC7963" }}>
                {minute} : {second}
              </span>
            </>
          )}
        </p>
        <button
          onClick={() => {
            setRemainTime(300);
            setCurrentId(short_uuid());
          }}
          className={styles.againButton}
        >
          {trans("__regenerate")} <span className={styles.again}>⟳</span>
        </button>
      </div>
    </>
  );
};

const LoginPopup = (props) => {
  const { t } = useTranslation();
  const trans = useCallback((key) => {
    return t(`components.login.popup.${key}`)
  });
  const pathname = useLocation().pathname;
  const qrcodeId = useLocation().search.replace("?uuid=", "");
  const [moeUrl, setMoeUrl] = useState("");
  const getRememberLocal = localStorage.getItem("remember");
  const [{ popupState }, loginDispatch] = useStore(StoreTypes.login);
  const [rememberMe, setRememberMe] = useState(getRememberLocal ? true : false);
  const [errormessage, seterrmessage] = useState("");
  const [UserParams, setUserParams] = useSetState({
    Account: getRememberLocal || "",
    Password: "",
  });
  const [remark, setRemark] = useSetState({
    Account: "",
    Password: "",
  });
  const inputList = [
    {
      Title: trans("__username"),
      Attion: "",
      Icon: "/assets/icon/account.svg",
      Input: {
        Type: "text",
        Name: "Account",
        Value: UserParams.Account,
        Placeholder: trans("__enterUsername"),
      },
      Remark: remark.Account,
    },
    {
      Title: trans("__password"),
      Attion: trans("__caseSensitive"),
      Icon: "/assets/icon/password.svg",
      Input: {
        Type: "password",
        Name: "Password",
        Value: UserParams.Password,
        Placeholder: trans("__enterPassword"),
      },
      Remark: remark.Password,
    },
  ];
  const updateUserParams = (e) => {
    setUserParams({ [e.target.name]: e.target.value });
  };
  const Submit = useCallback(async () => {
    setRemark({
      Account: UserParams.Account ? "" : trans("__enterValidUsername"),
      Password: UserParams.Password ? "" : trans("__enterPasswordInput"),
    });

    if (UserParams.Account === "" || UserParams.Password === "") return false;

    rememberMe
      ? localStorage.setItem("remember", UserParams.Account)
      : localStorage.setItem("remember", "");

    const { code, message, jwt } = await sendData(
      UserParams.Account,
      UserParams.Password
    );

    if (code === "SUCCESS") {
      window.electron && window.electron.ipcRenderer.send("set-token", code);
      await loginDispatch({ type: LOGIN_POPUP, popupState: false });

      EventBus.emit({ event: UserEvent.LoginEvent });

      setUserParams({
        Account: rememberMe ? UserParams.Account : "",
        Password: "",
      });

      seterrmessage("");
      if (pathname === "/qrcodelogin") {
        const res = await qrcodeAddTokenApi({
          id: qrcodeId,
          jwt: JSON.parse(jwt).jwt,
        });
        if (res.status === "success") {
          loginDispatch({ type: LOGIN_POPUP, popupState: false });
          props.onChangeOnepassStatus(res.status);
        } else {
          if (res.error.message === "please reload qrcode page") {
            props.onChangeOnepassStatus(res.error.message);
          }
        }
      }
    } else {
      seterrmessage(message);
    }
  }, [UserParams.Account, UserParams.Password, loginDispatch, pathname, props, qrcodeId, rememberMe, setRemark, setUserParams, trans]);

  const handleKeyPress = (event) => {
    event.key === "Enter" && Submit();
  };
  const closePopup = (e, className) => {
    const el = e.target.className.trim();
    el === className.trim() &&
      loginDispatch({ type: LOGIN_POPUP, popupState: false });
  };

  const closeBtn = () => {
    loginDispatch({ type: LOGIN_POPUP, popupState: false });
    EventBus.emit({
      event: ReaderToolsEvent.SetSyncCameraEvent,
      payload: { isOpen: false },
    });
  };

  useEffect(() => {
    getMoeLoiginUrl().then((url) => {
      setMoeUrl(url);
    });
  }, []);

  const loginteachCloud = (url) => {
    window.electron && window.electron.ipcRenderer.send("checkThirdCode", url);
  };
  return (
    <div
      className={`${styles.loginPopup__background} ${
        popupState ? "" : styles.active
      } ${
        pathname === "/qrcodelogin" && styles.loginPopup__background__qrcode
      }`}
    >
      <div className={styles.loginPopup__box}>
        {pathname !== "/qrcodelogin" && (
          <div
            className={styles.loginPopup__close}
            onClick={() => {
              closeBtn();
            }}
          >
            <div className={styles.crossLine__one}></div>
            <div className={styles.crossLine__two}></div>
          </div>
        )}
        <h5>{trans("__loginOneBook")}</h5>
        {errormessage ? (
          <div className={styles.loginPopup__message}>{errormessage}</div>
        ) : (
          ""
        )}
        <div className={styles.loginPopup__content}>
          <div className={styles.loginPopup__box__left}>
            {window.electron ? (
              <div className={styles.btnMoe}>
                <div
                  className={styles.btnMoe2}
                  onClick={() => loginteachCloud(moeUrl)}
                >
                  <img
                    src="/img/moe.png"
                    height="24"
                    width="24"
                    alt={trans("__educationCloudAccount")}
                  />
                  {trans("__educationCloudAccount")}
                </div>
              </div>
            ) : (
              <div className={styles.btnMoe}>
                <a className="btn btn-blue btn-block" href={moeUrl}>
                  <img
                    src="/img/moe.png"
                    height="24"
                    width="24"
                    alt={trans("__educationCloudAccount")}
                  />
                  {trans("__educationCloudAccount")}
                </a>
              </div>
            )}
            <hr />
            <div className={styles.naniAccount}>{trans("__naniAccount")}</div>
            <div className={styles.tip}>{trans("__enterUsernamePassword")}</div>

            {inputList.map((item) => {
              return (
                <div
                  className={styles.loginPopup__inputbox}
                  key={item.Input.Name}
                >
                  <img
                    className={styles.icon}
                    src={item.Icon}
                    height="20"
                    width="20"
                    alt={item.Title}
                  />
                  <Input
                    type={item.Input.Type}
                    value={item.Input.Value}
                    name={item.Input.Name}
                    placeholder={item.Input.Placeholder}
                    onChange={updateUserParams}
                    onKeyPress={(e) => {
                      handleKeyPress(e);
                    }}
                  />
                  {item.Remark ? <span>{item.Remark}</span> : ""}
                </div>
              );
            })}
            <div className={styles.login__setting}>
              <label className={styles.loginPopup__remeber}>
                <span className={styles.mark}>✓</span>
                <input
                  checked={rememberMe}
                  type="checkbox"
                  onClick={() => {
                    setRememberMe(!rememberMe);
                  }}
                />
                <span>{trans("__rememberUsername")}</span>
              </label>
              <div className={styles.loginPopup__link}>
                <a
                  // href="https://oneclub.oneclass.com.tw/forgetpassword/"
                  href="#"
                  target="blank"
                >
                  {trans("__forgotPassword")}
                </a>
                <a
                  // href="https://oneclub.oneclass.com.tw/signup/"
                  href="#"
                  target="blank"
                >
                  {trans("__registerAccount")}
                </a>
              </div>
            </div>

            <div
              className={`${styles.loginPopup__btn} ${styles.loginPopup__btnlogin}`}
              onClick={() => Submit()}
            >
              <span>{trans("__login")}</span>
            </div>
          </div>
          {pathname !== "/qrcodelogin" && (
            <div className={styles.loginPopup__box__right}>
              <img
                src="assets/img/onepass.svg"
                alt="onePass"
                width="50%"
                className={styles.onePass}
              />
              <p>{trans("__mobileBarcodeLogin")}</p>
              <CountDownTimer onChangeErrorMessage={seterrmessage}/>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
