import React, { useEffect, useState } from "react";
import useSetState from "customHooks/setState";
import { WordLearningContentWrapper } from "components/WordLearningGrid/WordLearningContent";
import * as actionTypes from "constants/actionTypes";
import { useStore, StoreTypes } from "context";
import styles from "./index.module.scss";
import classnames from "classnames";
import WordContentList from "components/WordContentList";
import { ReaderToolsEvent } from "events/EventTypes";
import { EventBus, EventBusType } from "events/EventBus";
import AnchorAudioButton from "./AnchorAudioButton";
import Repository from "repositories/Repository";
import { useTranslation } from "react-i18next";

const { ExtendedResourceRepository } = Repository;

const WordExample = ({ data, defaultWord, isZhuyin }) => {
  const { t } = useTranslation();
  const [{ bookId }] = useStore(StoreTypes.books);
  const [extensionList, setExtensionList] = useState([]);
  const [{ currentWord, wordSetBtn }, setState] = useSetState({
    currentWord: defaultWord || Object.values(data)[0].Word,
    wordSetBtn: data[defaultWord].WordsSet || null,
  });

  useEffect(() => {
    (async () => {
      if (!bookId) return;
      const wordPromise = [];
      wordSetBtn.forEach((word, index) => {
        wordPromise.push(
          new Promise(async (resolve, reject) => {
            const htmlJson = await ExtendedResourceRepository.getJSONContent({
              bookId,
              pathName: word.ContentPath
                ? word.ContentPath.split(".json")[0]
                : "",
            });
            if (htmlJson) {
              resolve({
                htmlJson,
                index,
                type: word.type,
                CommentAudio: word.CommentAudio,
                ExampleAudio: word.ExampleAudio,
              });
            }
          })
        );
      });

      await Promise.all(wordPromise).then((result) => {
        setExtensionList(result);
      });
    })();
  }, [bookId, wordSetBtn]);

  const [, wordGridDispatch] = useStore(StoreTypes.wordGrid);

  const wordsChangeHandler = ({ word }) => {
    const eventBusType = EventBusType.ExtendedContent;
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ClickEraseAllEvent,
    });
    setState({
      currentWord: word,
      wordSetBtn: data[word].WordsSet,
    });
  };

  const convertData = (data) =>
    Object.entries(data).map(([key, item]) => ({
      label: item.Label || item.Word || item.label || item.word,
      value: key,
      relatedWords: [key],
    }));

  const formatData = (data) =>
    data.map((item) => ({
      Content: item.ContentPath || item.Content,
      Audio: item.AudioPath || item.Audio,
      Path: item.ImagePath || item.Path,
      Label: item.Label || item.label,
    }));

  const onScrollToAnchor = (tabIndex) => {
    wordGridDispatch({
      type: actionTypes.SET_CHANGE_TAB_INDEX,
      payload: {
        tabIndex,
      },
    });
  };

  return (
    <div className={styles.WordExample}>
      {extensionList.length > 0 && (
        <div className={styles.breakAnchor}>
          {extensionList.map((item, index) => {
            const example = item.htmlJson.html.includes("例");
            switch (index) {
              case 0:
                return (
                  <>
                    <AnchorAudioButton
                      audioSrc={item.CommentAudio}
                      onScrollToAnchor={() => onScrollToAnchor([index, 0])}
                    >
                      {t(
                        "components.WordExample.__explanationOfTerms",
                        "語詞解釋"
                      )}
                    </AnchorAudioButton>
                    {example && (
                      <AnchorAudioButton
                        audioSrc={item.ExampleAudio}
                        onScrollToAnchor={() => onScrollToAnchor([index, 1])}
                      >
                        {t(
                          "components.WordExample.__exampleSentences",
                          "語詞例句"
                        )}
                      </AnchorAudioButton>
                    )}
                  </>
                );
              default:
                return (
                  <>
                    <AnchorAudioButton
                      audioSrc={item.CommentAudio}
                      onScrollToAnchor={() => onScrollToAnchor([index, 0])}
                    >
                      {t(
                        "components.WordExample.__explanationOfTerms",
                        "語詞解釋"
                      )}
                      {index}
                    </AnchorAudioButton>
                    <AnchorAudioButton
                      audioSrc={item.ExampleAudio}
                      onScrollToAnchor={() => onScrollToAnchor([index, 1])}
                    >
                      {t(
                        "components.WordExample.__exampleSentences",
                        "語詞例句"
                      )}
                      {index}
                    </AnchorAudioButton>
                  </>
                );
            }
          })}
        </div>
      )}
      <div
        className={classnames(styles.content, {
          [styles.hasBreakAnchor]:
            wordSetBtn[0].Content || wordSetBtn[0].ContentPath,
        })}
      >
        <WordLearningContentWrapper
          data={formatData(data[currentWord].WordsSet)}
        />
        <WordContentList
          zhuyin={isZhuyin}
          word={currentWord}
          className={styles.wordContentList}
          data={convertData(data)}
          onChange={wordsChangeHandler}
        />
      </div>
    </div>
  );
};

export default WordExample;
