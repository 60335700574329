import i18n from 'i18next';

const trans = (
    key,
    defaultValue,
    params,
) => {
  return i18n.t( "constants.scrollPositionType." + key, {...params, defaultValue}) || '';
};

export const ScrollPositionType = {
  Next: "next",
  Prev: "prev",
  None: "none",
};

export const ScrollDirection = {
  Vertical: "vertical",
  Horizontal: "horizontal"
}

export const ScrollLabel = {
  Prev: trans("__prev"),
  Next: trans("__next")
}

export const ScrollPositionButtonStateType = {
  Head: 'head',
  Middle: 'middle',
  Bottom: 'bottom',
  None: 'none',
}

export const ScrollCoordinate = {
  X:"x",
  Y: "y",
}

export const ScrollLength = 0.85