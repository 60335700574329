import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { CanvasFlipType } from 'constants/flipTypes';
import {useTranslation} from "react-i18next";

export const fixCssButton = {width: '100%', height: 'auto'}

const FlipTools = ({ target: object }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.flipTools.${key}`)
    };
    const flipHandler = useCallback(flipType => e => {
        EventBus.emit({ event: CanvasEvent.FlipCanvasEvent, payload: { object, flipType } });
    }, [object])

    return (
        <div className={styles.flipTools}>
            <h3>{trans("__flip")}</h3>
            <button style={fixCssButton} onClick={flipHandler(CanvasFlipType.HORIZONTAL)}>{trans("__horizontal")}</button>
            <button style={fixCssButton} onClick={flipHandler(CanvasFlipType.VERTICAL)}>{trans("__vertical")}</button>
        </div>
    )
};


export default FlipTools;
