/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import DialogModule from "components/Dialogs/";
import { LOGIN_POPUP, LOGIN_CONFIRM_POPUP ,LOGIN_BTN } from "constants/loginTypes";
import * as types from "constants/actionTypes";
import { ReaderToolsEvent } from "events/EventTypes";
import { EventBus } from "events/EventBus";
import {useTranslation} from "react-i18next";

const CheckOnlinePopup = () => {
	const { t } = useTranslation();
	const trans = (key) => {
		return t(`components.login.checkOnlinePopup.${key}`)
	};

	const [{ isOnLineDialog }, readerDispatch] = useStore(StoreTypes.reader);

	const onLineDialogTextField = (
		<div className={styles.onlineDialog}>
			<div className={styles.title}>{trans("__noInternet")}</div>
			<div className={styles.content}>{trans("__checkNetworkConnection")}</div>
		</div>
	);

	const closeOnLineDialog = () => {
		readerDispatch({ type: types.SET_ONLINE_DIALOG, isOnLineDialog: false });
		EventBus.emit({
			event: ReaderToolsEvent.SetSyncCameraEvent,
			payload: { isOpen: false },
		});
	};

	const onLineDialogAction = (
		<div
			className={styles.onlineDialogBtn}
			onClick={() => closeOnLineDialog()}
		>
			{trans("__confirm")}
		</div>
	);

	const setIsOnLineDialog=()=>{
		readerDispatch({ type: types.SET_ONLINE_DIALOG, isOnLineDialog: false });
	}

	return (
		<DialogModule
			open={isOnLineDialog}
			setOpen={setIsOnLineDialog}
			content={onLineDialogTextField}
			action={onLineDialogAction}
		/>
	);
};

export default CheckOnlinePopup;
