import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { ReaderZoomType } from 'constants/ReaderTools';
import { API } from 'api';
import { AnnotationType } from 'constants/annotationTypes';
import {
  useFlipBook,
} from 'customHooks/canvas';
import {
  useReadAnnotations,
  useCreateAnnotation,
  useUpdateAnnotation
} from 'customHooks/db';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { mergeCanvasJSON } from 'util/svg';
import {
  ActivityEvent,
  ReaderEvent,
  ReaderToolsEvent
} from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import {
  ReaderToolType
} from 'constants/ReaderTools';

import { useWindowSize } from 'customHooks/windowSize';

export const usePullAnnotations = () => {
  const [{ token }] = useStore(StoreTypes.user);
  const { readAnnotationById } = useReadAnnotations();
  const createAnnotation = useCreateAnnotation();
  const updateAnnotation = useUpdateAnnotation();

  const pullInteractiveMetaObjects = useCallback(
    async ({ id, book }) => {
      let pages = book.pageInfos.map(pageInfo => pageInfo.pageIndex);
      let remoteUpdatedAt = await API.postJson(
        `${process.env.REACT_APP_API_DOMAIN}/getInteractiveMetaObjectInfo`,
        { token: { jwt: token }, interactiveObjectId: id }
      ).then(res => {
        if (res.status === 'success') {
          return res.content.updatedAt;
        }
        return 0;
      });
      let remoteRecord = await Promise.all(
        pages.map(async page => {
          const result = await API.getJSON(
            `${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/interactiveMetaObjects/${id}/${page}.dat`
          );
          if (result) {
            return result.rawData;
          }
          return null;
        })
      );
      remoteRecord = remoteRecord.filter(record => record);
      const localRecord = await readAnnotationById({ id });
      if (localRecord) {
        if (localRecord.lastSyncedAt >= remoteUpdatedAt) {
          // local record is up-to-date, just use it
          return localRecord;
        } else if (!localRecord.isDirty) {
          // local is older and not modified for a while, overwrite with remote record
          return updateAnnotation(localRecord.id, {
            annotations: remoteRecord,
            lastSyncedAt: remoteUpdatedAt
          });
        } else {
          // local is modified but there is newer record from remote, merge
          let newAnnotations = remoteRecord.map(record => {
            let localAnnotation = localRecord.annotations.find(
              v => v.pageIndex === record.pageIndex
            );
            if (localAnnotation) {
              return {
                pageIndex: record.pageIndex,
                annotation: JSON.stringify(
                  mergeCanvasJSON(
                    JSON.parse(record.annotation),
                    JSON.parse(localAnnotation.annotation)
                  )
                )
              };
            } else {
              return record;
            }
          });
          newAnnotations = newAnnotations.concat(
            localRecord.annotations.filter(
              annotation =>
                !newAnnotations.some(v => v.pageIndex === annotation.pageIndex)
            )
          );
          return updateAnnotation(localRecord.id, {
            annotations: newAnnotations,
            lastSyncedAt: remoteUpdatedAt
          });
        }
      } else {
        // no local record, create with remote record
        return createAnnotation({
          id,
          bookId: book.bookId,
          annotations: remoteRecord,
          type: AnnotationType.INTERACTIVE_OBJECT,
          lastSyncedAt: remoteUpdatedAt
        });
      }
    },
    [token, readAnnotationById, updateAnnotation, createAnnotation]
  );

  return { pullInteractiveMetaObjects };
};

export const useRefreshReader = () => {
  const [{ annotationId }] = useStore(StoreTypes.annotation);
  const [{ defaultPageIndex }] = useStore(StoreTypes.books);
  const [{ isDoublePageMode }] = useStore(StoreTypes.reader);
  const { readAnnotationById } = useReadAnnotations();

  const refreshReader = useCallback(async annotation => {
    if (!annotation) {

      if (!annotationId) return;
      annotation = await readAnnotationById({ id: annotationId });
      EventBus.emit({ event: ReaderToolsEvent.TogglePageModeEvent, payload: { isDoublePageMode: annotation.isDoublePageMode, targetPageIndex: annotation.pageIndex } });
    } else {
      // 在課習和輔材之間切換時，透過 bookmarkRecord 和 defaultPageIndex 來判斷應該進到哪本書的哪一頁
      // 如果因為翻過書，紀錄過 bookmarkRecord，就用 bookmarkRecord 來判斷頁數。如果沒有 bookmarkRecord 就用 defaultPageIndex 來判斷
      // defaultPageIndex 就會去書本的目次
      let targetPageIndex;
      let index;
      if (annotation.bookmarkRecord) {
        const bookmarkIndex = Object.keys(annotation.bookmarkRecord).reverse();
        index = bookmarkIndex.find(index => {
          return annotation.bookmarkRecord[index].startPage === defaultPageIndex
        })
      }
      if (index) {
        targetPageIndex = annotation.isDoublePageMode ? Math.floor(annotation.bookmarkRecord[index].index / 2) : annotation.bookmarkRecord[index].index;
      } else {
        targetPageIndex = annotation.isDoublePageMode ? Math.floor(defaultPageIndex / 2) : defaultPageIndex;
      }

      EventBus.emit({ event: ReaderToolsEvent.TogglePageModeEvent, payload: { isDoublePageMode: annotation.isDoublePageMode, targetPageIndex } });
    }

    EventBus.emit({ event: ReaderEvent.RefreshCanvasEvent, payload: { result: annotation } });
  }, [annotationId, defaultPageIndex, readAnnotationById]);

  return { refreshReader };
};

export const useUpdateFullWidthInfo = () => {
  const [
    {
      isDoublePageMode,
      fullWidthInfo: { mode }
    },
    readerDispatch
  ] = useStore(StoreTypes.reader);
  const [
    {
      books,
      bookId,
      style: { width, height }
    }
  ] = useStore(StoreTypes.books);
  const book = books.find(book => book.bookId === bookId);

  const updateFullWidthInfo = useCallback((fullWidthMode = mode) => {
    if (!book || !width || !height) return;
    const { width: bookWidth, height: bookHeight } = book;
    let fullWidthInfo = { scale: 1, offset: 0, mode: fullWidthMode };
    if (mode && isDoublePageMode) {
      const bookScale = (bookWidth * 2) / bookHeight;
      const readerScale = width / height;
      if (bookScale < readerScale) {
        const factor = height / bookHeight;
        const offset = (width - bookWidth * 2 * factor) / 2;
        const scale = width / (bookWidth * 2 * factor);
        fullWidthInfo = { scale, offset, mode: fullWidthMode };
      }
    }
    readerDispatch({ type: types.SET_FULL_WIDTH_INFO, fullWidthInfo });
    // readerDispatch({ type: types.SET_SCALE_INFO, scale: fullWidthInfo.scale });
  }, [book, height, isDoublePageMode, mode, readerDispatch, width]);

  return updateFullWidthInfo;
};

export const useUpdateReaderScaleInfo = () => {
  const [{ zoomType }, readerDispatch] = useStore(StoreTypes.reader);
  const [
    {
      style: { width, height }
    }
  ] = useStore(StoreTypes.books);

  const updateReaderScaleInfo = useCallback(() => {
    if (!width || !height) return;
    let offsetX;
    let offsetY;
    let scale = 2;
    let isScaleInfo = true;

    switch (zoomType) {
      case ReaderZoomType.LeftTop:
        offsetX = 0;
        offsetY = 0;
        break;
      case ReaderZoomType.RightTop:
        offsetX = -((width * scale) / 2);
        offsetY = 0;
        break;
      case ReaderZoomType.LeftBottom:
        offsetX = 0;
        offsetY = -((height * scale) / 2);
        break;
      case ReaderZoomType.RightBottom:
        offsetX = -((width * scale) / 2);
        offsetY = -((height * scale) / 2);
        break;
      case ReaderZoomType.OriginZoom:
        offsetX = 0;
        offsetY = 0;
        scale = 1;
        break;
      case ReaderZoomType.WheelZoom:
      case ReaderZoomType.PanZoom:
        isScaleInfo = false;
        break;
      default:
        isScaleInfo = false;
        break;
    }

    //readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects: null })
    isScaleInfo && readerDispatch({ type: types.SET_SCALE_INFO, offsetX, offsetY, scale });
  }, [height, readerDispatch, width, zoomType]);

  return updateReaderScaleInfo;
};

export const useUpdateAreaZoomInfo = () => {
  const [{ offsetX, offsetY, scale, isDoublePageMode }, readerDispatch] = useStore(StoreTypes.reader);
  const [{ windowWidth }] = useStore(StoreTypes.global);
  const [{ bookContent, style }] = useStore(StoreTypes.books);
  const windowSize = useWindowSize();

  const updateAreaZoomInfo = useCallback(
    rect => {
      if (!rect || !rect.height) return;
      // make sure values in rect is always from left-top to right-bottom
      if (rect.originWidth < 0) {
        rect.originX += rect.originWidth;
        rect.originWidth = rect.originWidth * -1;
      }
      if (rect.originHeight < 0) {
        rect.originY += rect.originHeight;
        rect.originHeight = rect.originHeight * -1;
      }

      let newScale = style.height / rect.originHeight;
      if (style.width / style.height < rect.originWidth / rect.originHeight) {
        newScale = style.width / rect.originWidth;
      } else {

      }
      if (newScale >= 8) {
        newScale = 8;
      }

      const LRGap = (windowSize.width - style.width) * 0.5

      const styleW = style.width
      const styleH = style.height

      let centerX = (rect.originX - LRGap) + rect.originWidth * 0.5;
      let centerY = rect.originY + rect.originHeight * 0.5;

      centerX = centerX / scale - offsetX / scale
      centerY = centerY / scale - offsetY / scale

      //const maxScale = newScale * scale
      const maxScale = newScale * scale >= 8 ? 8 : newScale * scale

      const newOffsetX = -(centerX * maxScale) + style.width / 2;
      const newOffsetY = -(centerY * maxScale) + style.height / 2;

      const scaleX = (newOffsetX / ((styleW * maxScale) - styleW))
      const scaleY = (newOffsetY / ((styleH * maxScale) - styleH))
      const zoomInfo = {
        scaleX,
        scaleY,
        scale: newScale
      }

      EventBus.emit({
        event: ReaderToolsEvent.SetReaderZoomEvent,
        payload: { type: ReaderZoomType.AreaZoom }
      });
      readerDispatch({
        type: types.SET_SCALE_INFO,
        offsetX: newOffsetX,
        offsetY: newOffsetY,
        scale: maxScale
      });

      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      EventBus.emit({
        event: ReaderToolsEvent.SetReaderToolTypeEvent,
        payload: {
          readerToolType: ReaderToolType.Drag
        }
      });

      return zoomInfo;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offsetX, offsetY, scale, isDoublePageMode, bookContent, readerDispatch, style]
  );

  const updateAreaZoomEventInfo = useCallback(({ type, zoomInfo }) => {
    if (!type || !zoomInfo) return;
    const { scaleX, scaleY, scale } = zoomInfo
    const offsetX = (style.width * scale - style.width) * scaleX
    const offsetY = (style.height * scale - style.height) * scaleY
    EventBus.emit({
      event: ReaderToolsEvent.SetReaderZoomEvent,
      payload: { type }
    });

    readerDispatch({
      type: types.SET_SCALE_INFO,
      offsetX,
      offsetY,
      scale
    });

    EventBus.emit({
      event: ReaderToolsEvent.ClickDragEvent
    });
    EventBus.emit({
      event: ReaderToolsEvent.SetReaderToolTypeEvent,
      payload: {
        readerToolType: ReaderToolType.Drag
      }
    });

  }, [style, readerDispatch])

  return { updateAreaZoomInfo, updateAreaZoomEventInfo };
};

export const useUpdateAreaZoomForPageButton = () => {
  const [
    {
      isDoublePageMode,
      fullWidthInfo: { offset },
    },
    readerDispatch
  ] = useStore(StoreTypes.reader);
  const [
    {
      style: { width, height },
      bookContent
    }
  ] = useStore(StoreTypes.books);

  const updateAreaZoomForPageButton = useCallback(
    rect => {
      if (!rect || !rect.height) return;
      const bookContentInfo = bookContent[Object.keys(bookContent).find(key => bookContent[key])]
      const pageContentWidth = isDoublePageMode ? bookContentInfo.width * 2 : bookContentInfo.width;
      const pageContentHeight = bookContentInfo.height;
      const pageScale = height / pageContentHeight;
      const whiteGap = (offset) ? 0 : (width - pageContentWidth * pageScale) / 2;
      const pageViewWidth = (offset) ? width : pageContentWidth * pageScale;
      const baseScale = isDoublePageMode ? 2 : 1;
      const scale = (width * baseScale) / pageViewWidth;
      const rectX = rect.originXScale * width
      const rectY = rect.originYScale * height
      const offsetX = rectX < width / baseScale ? -whiteGap * scale : -((width + whiteGap * scale));
      let offsetY = -(rectY * scale);

      if (offsetY < -(height * scale - height)) {
        offsetY = -(height * scale - height);
      }

      EventBus.emit({
        event: ReaderToolsEvent.SetReaderZoomEvent,
        payload: { type: ReaderZoomType.AreaZoom }
      });
      readerDispatch({
        type: types.SET_SCALE_INFO,
        offsetX,
        offsetY,
        scale: scale
      });
    },
    [bookContent, height, isDoublePageMode, offset, readerDispatch, width]
  );
  return updateAreaZoomForPageButton;
};

export const useSetPageIndex = () => {
  const reducers = useStore();
  const [{ annotationId }] = reducers[StoreTypes.annotation];
  const [{ catalog }] = reducers[StoreTypes.books];
  const [{ isDoublePageMode }, readerDispatch] = reducers[StoreTypes.reader];
  const flipBook = useFlipBook();
  const { readAnnotationById } = useReadAnnotations();
  const updateAnnotation = useUpdateAnnotation();
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();
  const { refreshReader } = useRefreshReader();

  const setPageIndex = useCallback(
    async ({ pageIndex, convertToSVG }) => {
      readerDispatch({ type: types.SET_SUBMENU_INFO, submenuInfo: null })
      readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects: null })

      // flipBook({ convertToSVG, keepCanvas: true, pageIndex });
      // reset scaling
      readerDispatch({
        type: types.SET_SCALE_INFO,
        offsetX: 0,
        offsetY: 0,
        scale: 1
      });
      EventBus.emit({
        event: ActivityEvent.SendActivityInfoToFirebaseEvent,
        payload: {
          pageIndex: pageIndex
        }
      });

      // reset to OriginZoom status after flip book
      EventBus.emit({
        event: ReaderToolsEvent.SetReaderZoomEvent,
        payload: { type: ReaderZoomType.OriginZoom }
      });

      const annotation = await readAnnotationById({ id: annotationId });
      const bookmarkObj = {};

      const hasCatalog = catalog.length > 0;
      if (hasCatalog) {
        // save pageIndex by single page mode
        const singlePageModeIndex = isDoublePageMode ? pageIndex * 2 : pageIndex;
        const { startPage, bookmarkIndex } = catalog.getBookmarkIndex(singlePageModeIndex);
        bookmarkObj[bookmarkIndex] = { startPage, index: singlePageModeIndex };
      }

      const bookmarkRecord = { ...annotation.bookmarkRecord, ...bookmarkObj }
      const result = await updateAnnotation(annotationId, {
        pageIndex,
        isDoublePageMode,
        updatedAt: Date.now(),
        bookmarkRecord: hasCatalog ? bookmarkRecord : null
      });

      strategy && strategy.syncSingleAnnotation({
        annotations: null,
        localDB: { ...result, annotations: [] },
        id: annotationId
      });
      refreshReader();
      // EventBus.emit({ event: ReaderToolsEvent.TogglePageModeEvent, payload: { isDoublePageMode: result.isDoublePageMode, targetPageIndex: result.pageIndex } });
      // EventBus.emit({ event: ReaderEvent.RefreshCanvasEvent, payload: { result: result } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [annotationId, catalog, flipBook, isDoublePageMode, readAnnotationById, readerDispatch, strategy, updateAnnotation]

  );
  return { setPageIndex };
};
