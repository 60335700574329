import React, { useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { ExtraFieldsForEditor } from 'constants/canvas'
import {
  MeasureToolType
} from 'constants/painterTypes';
import {useTranslation} from "react-i18next";

export const MeasureToolsComponent = ({ object }) => {

  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.tools.propertyForm.measureToolsPropertyForm.${key}`)
  };

  const MeasureToolsMap = {
    [MeasureToolType.Protractor]: trans("__protractor"),
    [MeasureToolType.LongRuler]: trans("__longRuler"),
    [MeasureToolType.ShortRuler]: trans("__shortRuler"),
    [MeasureToolType.IsoscelesTriangle]: trans("__isometricTriangleBoard"),
    [MeasureToolType.RightTriangle]: trans("__rightAngleTriangleBoard"),
  }

  const measureToolType = object[ExtraFieldsForEditor.MeasureToolType] ? object[ExtraFieldsForEditor.MeasureToolType] : ""

  const changeHandler = useCallback((value) => {
    EventBus.emit({
      event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
      payload: { object, property: ExtraFieldsForEditor.MeasureToolType, value }
    });
  }, [object]);

  return (
    <>
      <select
        id="mathTools"
        value={measureToolType}
        onChange={e => changeHandler(e.target.value)}
      >
        {measureToolType === "" && (
          <option key={-1} value={''}>
            {
              trans("__pleaseSelect")
            }
          </option>
        )}
        {Object.values(MeasureToolType).map(
          (type, i) =>
            MeasureToolsMap[type] && (
              <option key={i} value={type}>
                {MeasureToolsMap[type]}
              </option>
            )
        )}
      </select>
    </>
  )
};