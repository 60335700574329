import React,{ useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { ToolBarDirection } from 'constants/ReaderTools';
import ToolSectionTitle from 'components/Tools/ToolSectionTitle';
import { useStore, StoreTypes } from 'context';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";


const ToolsPosition = () => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.toolsPosition.${key}`)
    };

  const [{ readerToolDirection }] = useStore(StoreTypes.reader);
  const changeHandler = useCallback((direction)=>{
      EventBus.emit({
        event: ReaderToolsEvent.ChangeReaderToolDirectionEvent,
        payload: direction
    })
  },[])

    const position = [
        { label: trans("__bottom"), value: ToolBarDirection.BOTTOM },
        { label: trans("__top"), value: ToolBarDirection.TOP },
        { label: trans("__left"), value: ToolBarDirection.LEFT },
        { label: trans("__right"), value: ToolBarDirection.RIGHT }
    ];

  return (
    <div className={styles.position_box}>
      <ToolSectionTitle label={trans("__toolbarPosition")} />
      <div className={styles.position}>
        {
          position.map(item=>
            <div className={styles.option} key={item.value}>
              <label>
                <input
                  type='radio'
                  name="positionSelect"
                  value={item.value}
                  checked={readerToolDirection === item.value}
                  onChange={(e)=>changeHandler(e.target.value)}
                />
                <span className={styles.text}>{item.label}</span>
              </label>
            </div>
          )
        }
      </div>
    </div>
);
}
export default ToolsPosition;
