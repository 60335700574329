import React, { useCallback } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import { FontSizeType } from 'constants/fontSizeType';
import { EventBusType } from 'events/EventBus';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import {useTranslation} from "react-i18next";

const eventBusType = EventBusType.ExtendedContent;


export const ExtendedFontSizeButton = ({
  value = FontSizeType.Medium
}) => {
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.extendedContent.extendedFontSizeButton.${key}`)
  };

  const changePopWindowFontSizeHandler = useCallback(fontSize =>{
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowFontSizeEvent,
      payload: { fontSize }
    });
  },[])

  const buttonListData = [
    {
      label: trans("__large"),
      value: FontSizeType.Large
    },
    {
      label: trans("__medium"),
      value: FontSizeType.Medium
    },
    {
      label: trans("__small"),
      value: FontSizeType.Small
    }
  ];

  return (
    <div className={styles.fontSizeButton}>
      {buttonListData.map((item,index) => (
        <div
          className={classnames(styles.button, {
            [styles.active]: value === item.value
          })}
          key={index}
          onClick={() => changePopWindowFontSizeHandler(item.value)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};
