import React, { useCallback, useRef, useEffect } from 'react';
import { questionTypes } from 'constants/questionTypes';
import Icon from 'components/Icon';
import AudioButton from 'components/common/AudioButton';
import useSetState from 'customHooks/setState';
import { useBoolean } from 'customHooks/boolean';
import classnames from 'classnames';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";


const TFTestOptions = [
  { label: 'circle', value: true },
  { label: 'times', value: false },
];

const returnBreakTag = str => str.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' ');


const Choice = ({ options = [], value, isPanelShow }) => {
  const val = value.toString();
  const audioYesBtn = useRef();
  const audioNoBtn = useRef();
  const [{ optionList }, setState] = useSetState({ optionList: options.filter(item => item).map(option => ({ option, isActive: false })) });

  useEffect(()=>{
    const updatedOptionList = options.filter(item => item).map(option => ({ option, isActive: false }))
    setState({ optionList:updatedOptionList })
  },[options, setState,isPanelShow])

  const clickHandler = useCallback((index, isCorrect) => {
    const temp = optionList.concat();
    temp[index].isActive = true;
    setState({ optionList: temp })
    isCorrect ? audioYesBtn.current.click() : audioNoBtn.current.click();
  }, [optionList, setState])


  return (
    <div className={styles.choice}>
      <AudioButton
        className={styles.audioButton}
        src="assets/audio/no.mp3"
        ref={audioNoBtn}
      />
      <AudioButton
        className={styles.audioButton}
        src="assets/audio/yes.mp3"
        ref={audioYesBtn}
      />
      {
        optionList.length > 0 && optionList.map((item, index) => (
          <div className={classnames(styles.options, {
            [styles.active]: item.isActive,
            [styles.correct]: val === index.toString(),
            [styles.wrong]: val !== index.toString()
          })}
            onClick={() => clickHandler(index, val === index.toString())}
          >
            <span className={styles.icon}>
              {val === index.toString() && <Icon type="text" name="check" />}
              {val !== index.toString() && <Icon type="text" name="times" />}
            </span>
            <div>{item.option}</div>
          </div>)
        )
      }
    </div>
  )
}

const TFTest = ({ value, isPanelShow }) => {
  const audioYesBtn = useRef();
  const audioNoBtn = useRef();

  const [{ optionList, activeIndex }, setState] = useSetState(
    {
      optionList: TFTestOptions,
      activeIndex: null
    }
  );

  useEffect(()=>{
    setState({ activeIndex: null });
  },[isPanelShow, setState])

  const clickHandler = useCallback((index, isCorrect) => {
    setState({ activeIndex: index })
    isCorrect ? audioYesBtn.current.click() : audioNoBtn.current.click();
  }, [setState]);

  return (
    <div className={styles.TFTest}>
      <AudioButton
        className={styles.audioButton}
        src="assets/audio/no.mp3"
        ref={audioNoBtn}
      />
      <AudioButton
        className={styles.audioButton}
        src="assets/audio/yes.mp3"
        ref={audioYesBtn}
      />
      {
        optionList.map((item, index) => (
          <div
            className={classnames(styles.options, {
              [styles.active]: activeIndex === index,
              [styles.correct]: value === item.value.toString(),
              [styles.wrong]: value !== item.value.toString()
            })}
            onClick={() => clickHandler(index, value === item.value.toString())}
          >
            <Icon type="text" name={item.label} />
          </div>
        ))
      }
    </div>
  )
}

const Assay = ({ value, isPanelShow }) => {
  const [isShowAns, { toggle, setFalse:hideAns }] = useBoolean();
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.common.player.questionPanel.${key}`)
  };
  useEffect(()=>{
    hideAns();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isPanelShow])

  return (
    <div className={styles.assay}>
      {
        value && <div
          className={classnames(styles.options, {
            [styles.show]: isShowAns
          })}
          onClick={() => toggle()}
        >
          <span>{isShowAns ? value : trans('__answer')}</span>
        </div>
      }
    </div>
  )
}


const QuestionPanel = ({
  data,
  showing = false,
  onClose,
  onRestartByIndex
}) => {
  const { title, options, questionType, value } = data;
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.common.player.questionPanel.${key}`)
  };
  return (
    <div className={classnames(styles.questionPanel, {
      [styles.showing]: showing
    })}>
      <div className={styles.titleWrapper}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: returnBreakTag(title) }}
        />
      </div>
      <div className={styles.answersWrapper}>
        <div className={styles.answers}>
          {questionType === questionTypes.Choice && <Choice isPanelShow={showing} options={options} value={value} />}
          {questionType === questionTypes.TFTest && <TFTest isPanelShow={showing} value={value} />}
          {questionType === questionTypes.Assay && <Assay isPanelShow={showing} value={value} />}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.goOnButton} onClick={onRestartByIndex}>
          {trans('__reviewAgain')}
        </div>
        <div className={styles.goOnButton} onClick={onClose}>
          {trans('__continuePlaying')}
        </div>
      </div>
    </div>
  )
};

export default QuestionPanel;
