import React from 'react';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import {useTranslation} from "react-i18next";

export const ReaderToolActivityAttendeeNumber = ({ type, attendeeNumber }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.readerTools.desktop.readerToolComponents.readerToolActivityAttendeeNumber.${key}`)
    };
    return (
        <div key={type} title={toolsEventMap[type].title}>
            {(attendeeNumber === "admin") ? trans("__teacher") : attendeeNumber}
        </div>
    );
};
