import React from 'react';
import ToolSectionTitle from 'components/Tools/ToolSectionTitle';
import SwitchButton from 'components/common/SwitchButton';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";


const ObjectPointerEventTools = () => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.objectPointerEventTools.${key}`)
    };

    const [{ objectPointerEventStatus }] = useStore(StoreTypes.reader);

  const onChangeHandler = val => {
    EventBus.emit({ event: ReaderToolsEvent.SetObjectPointerStatusEvent, payload: { objectPointerEventStatus: val } })
  }

  return (
    <div className={styles.objectPointerEventTools}>
      <ToolSectionTitle label={trans("__brushClickButton")} />
      <SwitchButton value={objectPointerEventStatus} onChange={val=>onChangeHandler(val)}/>
    </div>
  )
};

export default ObjectPointerEventTools;
