import React from 'react';
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import styles from './index.module.scss';
import { makeStyles } from '@material-ui/core';

const useAutoCompeleteStyles = makeStyles({
  root: {
    '& .MuiInput-underline.Mui-focused:after': {
      borderBottom: '2px solid #EC7963'
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #EC7963'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#EC7963',
    }
  }
})

export default function Tags({ title, optionsData = [], onChange }) {
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.autocomplete.${key}`)
  };
  return (
    <div className={styles.autocomplete}>
      <Autocomplete
        classes={useAutoCompeleteStyles()}
        options={optionsData}
        noOptionsText={trans('__clearCurrentSelection')}
        getOptionLabel={(option) => option.label}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={title}
          />
        )}
      />
    </div>
  );
}
