import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBusType, EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import {useTranslation} from "react-i18next";

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedAnswersButton = () => {

  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.extendedContent.extendedAnswersButton.${key}`)
  };
  const [{ isShowAnswer }] = useStore(StoreTypes.extendedContent);

  const changePopWindowAnswersHandler = useCallback(() => {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowAnswersEvent,
      payload:{
        isShowAnswer:!isShowAnswer
      }
    });
  }, [isShowAnswer])

  return (
    <div className={styles.answersButton}>
      <div className={styles.answers} onClick={() => changePopWindowAnswersHandler()}>{trans("__answer")}</div>
    </div>
  )
}
