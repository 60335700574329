import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import Icon from 'components/Icon';
import Button from 'components/Button/index';
import Tab from 'components/Tab';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { PainterMode } from 'constants/painterModes';
import { StampType } from 'constants/stampTypes';
import tabData from "./newTabData.json";

export const StampPanel = () => {
  const [currentTabIndex, setTabIndex] = useState(0);
  const changeStampTypeHandler = useCallback((stampType) => () => {

    EventBus.emit({
      event: ReaderToolsEvent.ChangeStampStatusEvent,
      payload: { painterMode: PainterMode.Stamp, stampType }
    });
  }, []);

  return (
    <>
      <Tab
        data={tabData}
        onChange={index => setTabIndex(index)}
        currentIndex={currentTabIndex}
      />
      {
        tabData[currentTabIndex] && (
          <div className={styles.stampPanel} >
            {
              tabData[currentTabIndex].stamps.length > 0 ?
                tabData[currentTabIndex].stamps.map((icon, i) => {
                  return (
                    <Button key={i} onClick={changeStampTypeHandler(StampType[icon])} >
                      <Icon name={icon} />
                    </Button>
                  )
                }) :
                <div>coming soon for {tabData[currentTabIndex].label}</div>
            }
          </div>
        )
      }
    </>
  )
}
