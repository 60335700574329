import React, { forwardRef } from 'react';
import CheckBox from 'components/common/CheckBox';
import Select from 'components/common/Select';
import classnames from 'classnames';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";

const timesData = [
  {
    label: 1,
    value: 1
  },
  {
    label: 2,
    value: 2
  },
  {
    label: 3,
    value: 3
  }
];


const timeGapData = [
  { label: '1', value: 1000 },
  { label: '2', value: 2000 },
  { label: '3', value: 3000 },
  { label: '4', value: 4000 },
  { label: '5', value: 5000 },
  { label: '6', value: 6000 },
  { label: '7', value: 7000 }
];

const SettingPanel = forwardRef(({
  className,
  value,
  active,
  advancedSettings,
  onTimesChange,
  onSpeedChange,
  onTimeGapChange,
  onUsingDefaultChange,
  hideDefaultCheckBox
}, ref) => {
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.common.player.settingPanel.${key}`)
  };
  const { speed, timeGap, times, usingDefault } = value;
  const timesChangeHandler = val => {
    onTimesChange(val);
  };

  const speedChangeHandler = val => {
    onSpeedChange(val);
  };

  const onTimeGapChangeHandler = val => {
    onTimeGapChange(val);
  }

  const checkBoxChangeHandler = checked => {
    onUsingDefaultChange(checked)
  }

  const cancelDefaultHandler = () => {
    checkBoxChangeHandler(false);
  }

      const speedData = [
        {
          label: trans('__slow'),
          value: 0.75
        },
        {
          label: trans('__normal'),
          value: 1
        },
        {
          label: trans('__fast'),
          value: 1.25
        }
      ];
  return (
    <div
      className={classnames(styles.settingPanel, className, {
        [styles.active]: active
      })}
      ref={ref}
    >
      {
        !hideDefaultCheckBox && (
          <div className={classnames(styles.controlSection)}>
            <CheckBox
              checked={usingDefault}
              onChange={checkBoxChangeHandler}
            >{trans('__defaultSettings')}</CheckBox>
          </div>
        )
      }
      {
        advancedSettings && (
          <>
            <div className={styles.controlSection}>
              <div className={styles.label}>{trans("__readTimes")}：</div>
              <div className={styles.selectGroup}>
                {
                  usingDefault ? (
                    <div className={styles.defaultWording} onClick={cancelDefaultHandler}>{trans("__pleaseSelect")}</div>
                  ) : (
                      <Select className={styles.select} onChange={e => timesChangeHandler(e.target.value)} value={times}>
                        {timesData.map(item => (
                          <option value={item.value}>{item.label} 次 </option>
                        ))}
                      </Select>
                    )
                }
              </div>
            </div>
            <div className={styles.controlSection}>
              <div className={styles.label}>{trans("__sentenceInterval")}：</div>
              <div className={styles.selectGroup}>
                {
                  usingDefault ? (
                    <div className={styles.defaultWording} onClick={cancelDefaultHandler}>{trans("__pleaseSelect")}</div>
                  ) : (
                      <Select className={styles.select} onChange={e => onTimeGapChangeHandler(e.target.value)} value={timeGap}>
                        {timeGapData.map(item => (
                          <option value={item.value}>{item.label} {trans("__seconds")} </option>
                        ))}
                      </Select>
                    )
                }
              </div>
            </div>
          </>
        )
      }
      <div className={styles.controlSection}>
        <div className={styles.label}>{trans("__playbackSpeed")}：</div>
        <div className={styles.selectGroup}>
          <Select className={styles.select} onChange={e => speedChangeHandler(e.target.value)} value={speed}>
            {speedData.map(item => (
              <option value={item.value}>{item.label}</option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
);

export default SettingPanel;
