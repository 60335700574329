import React, { useCallback, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
// import useModal from 'components/common/Modal/useModal';
import PainterPanel from 'components/Painter/PainterPanel';
import MeasureToolsPanel from 'components/MeasureTools/MeasureToolsPanel';
import { ReaderToolBox } from 'components/ReaderToolBox';
import Toolbox from 'components/Toolbox';
import { useStore, StoreTypes } from 'context';
// import { useMedia } from 'customHooks/media';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent, PainterEvent } from 'events/EventTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import { useWindowSize } from 'customHooks/windowSize';
import ReaderToolComponents from './ReaderToolComponents';
import { useReaderToolsFactory } from './ReaderToolsFactory';
import { ReaderToolbox, ToolboxCategory, MeasureTools } from 'constants/ReaderTools'
import { useReadUserSettings } from 'customHooks/db';
import { useUpdateUserSettings } from 'customHooks/userSetting';
import Repository from 'repositories/Repository';
import { convertArrayToMap } from 'util/array';
const { ReaderToolBarRepository } = Repository;

// const MobileToolsModal = () => <div></div>;
// const MobileTools = () => {
//   const [, { openModal }] = useModal();
//   return <div className={styles.mobileReaderTools} onClick={openModal} />;
// };
const PanelState = {
  None: 'None',
  Pen: 'Pen',
  Highlighter: 'Highlighter',
  Shape: 'Shape',
  Line: 'Line',
  Expression: 'Expression',
  MeasureTools: 'MeasureTools'
};

const ReaderTools = props => {
  const reducers = useStore();
  const [{ role, userId }] = reducers[StoreTypes.user];
  const [{ readerToolColor, isToolboxShow: isReaderToolboxShow, readerTools }] = reducers[StoreTypes.reader];
  const [{ books, bookId }] = reducers[StoreTypes.books];
  // const device = useMedia();
  const windowSize = useWindowSize();
  const readUserSettings = useReadUserSettings();
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();
  const [{ painterToolType, painterTool, fillType, lineType }] = reducers[StoreTypes.canvas];
  const book = books.find(book => book.bookId === bookId);
  const { LRFlip } = book || {};

  const [panelState, setPanelState] = useState(PanelState.None);

  const [isToolBoxShow, setToolBoxShow] = useState(false);
  const toolBoxCloseHandler = useCallback(() => setToolBoxShow(false), []);

  const readerToolsRef = useRef();
  const ReaderToolsFactory = useReaderToolsFactory();

  const panelCloseHandler = useCallback(() => setPanelState(PanelState.None), []);

  const [leftReaderToolBars, setLeftReaderToolBars] = useState([]);
  const [rightReaderToolBars, setRightReaderToolBars] = useState([]);

  const [toolboxType, setToolboxType] = useState(ToolboxCategory.Settings);
  const [{ readerToolSetting }] = useStore(StoreTypes.settings);
  const updateUserSettings = useUpdateUserSettings();

  useEffect(() => {
    (async () => {
      if (bookId) {
        EventBus.emit({
          event: PainterEvent.LoadReaderToolSettingsEvent,
          payload: { role }
        });
      }
    })();
  }, [bookId, role]);

  useEffect(() => {
    readerToolsRef.current && EventBus.emit({ event: ReaderToolsEvent.SetReaderToolHeight, payload: { readerToolHeight: readerToolsRef.current.offsetHeight } });
  }, [windowSize, readerToolsRef]);

  const setReaderTools = useCallback(readerTools => {
    EventBus.emit({
      event: ReaderToolsEvent.SetReaderToolsEvent,
      payload: {
        readerTools
      }
    });
  }, [])

  useEffect(() => {
    (async () => {
      if (bookId) {
        const left = ReaderToolBarRepository.getLeftReaderTools();
        setLeftReaderToolBars(left);

        const middle = ReaderToolBarRepository.getMiddleReaderTools();
        setReaderTools(
          [
            ...middle.filter(item => !readerToolSetting.includes(item)).map(item => ({ id: item, isActive: false })),
            ...readerToolSetting.map(item => ({ id: item, isActive: true }))
          ]
        );

        const right = await ReaderToolBarRepository.getRightReaderTools(bookId);
        setRightReaderToolBars(right);
      }

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, setReaderTools]);

  const onColorChange = useCallback((color) => {
    EventBus.emit({ event: PainterEvent.ChangeBrushColorEvent, payload: { color } });
  }, []);

  const onLineWidthChange = useCallback(lineWidth => {
    EventBus.emit({ event: PainterEvent.ChangeBrushWidthEvent, payload: { lineWidth } });
  }, []);

  const onPainterChangeHandler = useCallback(painterType => {
    EventBus.emit({ event: PainterEvent.ChangePainterTypeEvent, payload: { painterType } });
  }, []);

  const onBrushChange = useCallback(e => {
    EventBus.emit({ event: PainterEvent.ChangeBrushTypeEvent, payload: { brushType: e.target.value } });
  }, []);

  const onFillTypeChangeHandler = useCallback(fillType => {
    EventBus.emit({ event: PainterEvent.ChangeShapeFillTypeEvent, payload: { fillType } });
  }, []);

  const toolboxTypeChangeHandler = useCallback(toolType => {
    setToolboxType(toolType)
  }, [])

  const onExpressionHandler = useCallback((expressionType) => {
    EventBus.emit({
      event: ReaderToolsEvent.PlayExpression,
      payload: { expressionType }
    });
    setPanelState(PanelState.None)
  }, [])

  const toolboxFilter = useCallback(
    arr => {
      // console.log('arr:',arr)
      return arr.filter(item => ReaderToolbox[toolboxType].includes(item.id) && !item.isActive)},
    [toolboxType])

  const readerToolsFilter = useCallback(arr => {
    return arr.filter(item => item.isActive)
  }, [])

  const measureToolsFilter = arr => arr.filter(item => MeasureTools.includes(item.id) && !item.isActive);

  const resetClickHandler = useCallback(async () => {
    const middle = ReaderToolBarRepository.getMiddleReaderTools();
    const defaultTools = ReaderToolBarRepository.getDefaultReaderTools();

    setReaderTools(
      [
        ...middle.filter(item => !defaultTools.includes(item)).map(item => ({ id: item, isActive: false })),
        ...defaultTools.map(item => ({ id: item, isActive: true }))
      ]
    )

    updateUserSettings({ readerToolSetting: defaultTools }, true);

  }, [setReaderTools, updateUserSettings])

  const onLineTypeChangeHandler = useCallback(lineType => {
    EventBus.emit({ event: PainterEvent.ChangelineTypeEvent, payload: { lineType } });
  }, [])

  return (
    <div id="Reader-Tools" ref={readerToolsRef} style={{ backgroundColor: readerToolColor }}>
      <div
        className={classnames(
          styles.readerTools,
          styles[props.className]
        )}
      >
        {panelState === PanelState.Pen &&
          <PainterPanel.Pen
            toolType={painterToolType}
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onPainterHandler={onPainterChangeHandler}
            onBrushChange={onBrushChange}
            onLineTypeChange={onLineTypeChangeHandler}
            onShapeChange={onPainterChangeHandler}
            onFillTypeChange={onFillTypeChangeHandler}
            shapeFillTypeValue={fillType}
          />
        }
        {panelState === PanelState.Highlighter &&
          <PainterPanel.Pen
            toolType={painterToolType}
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onPainterHandler={onPainterChangeHandler}
            onBrushChange={onBrushChange}
            onLineTypeChange={onLineTypeChangeHandler}
            onShapeChange={onPainterChangeHandler}
            onFillTypeChange={onFillTypeChangeHandler}
            shapeFillTypeValue={fillType}
          />}
        {panelState === PanelState.Shape &&
          <PainterPanel.Shape
            toolType={painterToolType}
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onShapeChange={onPainterChangeHandler}
            onFillTypeChange={onFillTypeChangeHandler}
            shapeFillTypeValue={fillType}
            onLineTypeChange={onLineTypeChangeHandler}
          />}
        {panelState === PanelState.Line &&
          <PainterPanel.Line
            toolType={painterToolType}
            painterTool={painterTool[painterToolType]}
            onPanelClose={panelCloseHandler}
            onLineWidthChange={onLineWidthChange}
            onColorChange={onColorChange}
            onLineTypeChange={onLineTypeChangeHandler}
            onPainterHandler={onPainterChangeHandler}
          />}
        {panelState === PanelState.Expression &&
          <PainterPanel.Expression
            onPanelClose={panelCloseHandler}
            onExpressionHandler={onExpressionHandler}
          />}

        { panelState === PanelState.MeasureTools && 
          <MeasureToolsPanel
            onPanelClose={panelCloseHandler}>
              {/* <ReaderToolComponents
                type="MeasureTools"
                className={styles.measureTools}
                dragableClassName={styles.middleTools}
                bookInfo={{ book, LRFlip }}
                panelState={PanelState}
                setPanelStateHandler={setPanelState}
                filter={arr => measureToolsFilter(arr)}
                dropSelf={false}
                dragable={false}
              /> */}
          </MeasureToolsPanel>}

        {isToolBoxShow &&
          <ReaderToolBox
            onClose={toolBoxCloseHandler}
          />}
        <div className={styles.left}>
          {strategy && strategy.getReaderToolComponents(
            ReaderToolsFactory.batchCreateReaderTools(leftReaderToolBars)
          ).map(component => component)}
        </div>
        <ReaderToolComponents
          type="ReaderTool"
          className={styles.middle}
          dragableClassName={styles.middleTools}
          bookInfo={{ book, LRFlip }}
          panelState={PanelState}
          setPanelStateHandler={setPanelState}
          filter={arr => readerToolsFilter(arr)}
          dropSelf={true}
          dragable={isReaderToolboxShow}
          nowPanelState={panelState}
        />
        <div className={styles.right}>
          {strategy && strategy.getReaderToolComponents(
            ReaderToolsFactory.batchCreateReaderTools(rightReaderToolBars, {
              [ReaderToolType.OfflineReaderToolBox]: { additionalClickHandler: () => { setToolBoxShow(!isToolBoxShow); } },
            })
          ).map(component => component)}
        </div>
      </div>
      {
        isReaderToolboxShow && (
          <Toolbox
            toolboxType={toolboxType}
            onChange={toolboxTypeChangeHandler}
            onResetClick={resetClickHandler}
          >
            {
              ReaderToolbox[toolboxType].length > 0 && (
                <ReaderToolComponents
                  type="Toolbox"
                  isCollected={true}
                  className={styles.toolbox}
                  dragableClassName={styles.tools}
                  bookInfo={{ book, LRFlip }}
                  panelState={PanelState}
                  setPanelStateHandler={setPanelState}
                  filter={arr => toolboxFilter(arr)}
                  dropSelf={false}
                  dragable={isReaderToolboxShow}
                  nowPanelState={panelState}
                />
              )
            }
          </Toolbox>
        )
      }
    </div>
  );
};

export default ReaderTools;
