import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import styles from './list.module.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import dateformat from 'dateformat';
import Icon from 'components/Icon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    padding: '0',
    maxHeight: '345px',
    '& .MuiListItem-root': {
      height: '90px',
      background: 'white',
      padding: '16px',
      border: '1px solid #D5D7DE',
      borderRadius: '8px',
      marginBottom: '12px',
      overflow: 'hidden',
      '@media (max-height: 812px)': {
        marginBottom: '8px'
      },
      '&:hover': {
        background: '#FFF9F8',
        '&:has(.MuiIconButton-root:hover)':{
          background: 'white'
        }
      },
      '&.selectedAnnotation': {
        border: '3px solid #EC7963'
      }
    },
    '& .MuiListItemAvatar-root': {
      marginRight: '16px'
    },
    '& .MuiIconButton-root': {
      '&:hover': {
        backgroundColor: '#FFF9F8',
      },
    },
    '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }

});
const useTextFieldStyle = makeStyles({
  root: {
      '& .MuiInputLabel-root.Mui-focused': {
          color: '#EC7963'
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
              border: '2px solid #EC7963'
          }
      },
      '& .MuiOutlinedInput-input': {
          height: '15px',
      }
  }
})

function EnhancedTable(props) {

  const textFieldClasses = useTextFieldStyle();
  const { listData, setOpenWaring, openCopyAnnotationField, renameAnnotation, createNewAnnotation, createCopyAnnotation, renameIndex, setRenameIndex, selectedAnnotation, setSelectedAnnotation } = props;
  const [, annotationDispatch] = useStore(StoreTypes.annotation);
  const [listItem, setListItem] = useState(listData);
  const { i18n,t } = useTranslation();
  const trans = (key) => {
    return t(`components.annotationActivityTab.${key}`)
  };

  useEffect(() => {
    setListItem(listData);
  }, [listData]);

  const handleListItemClick = useCallback(async (event, annotationId, name, pageIndex) => {
    annotationDispatch({ type: types.UPDATE_ANNOTATION_INFO, annotationId, name, pageIndex });
    setSelectedAnnotation(annotationId);
  }, [annotationDispatch, setSelectedAnnotation]);

  //三點下拉選單相關
  const [annotationEl, setAnnotationEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [menuTargetIndex, setMenuTargetIndex] = useState(0);
  const openMenu = (event, index) => {
    setMenuTargetIndex(index);
    annotationDispatch({ type: types.UPDATE_ANNOTATION_INFO, annotationId: listData[index].id });
    setAnnotationEl(event.currentTarget);
    setOpen(true);
  };
  const closeMenu = () => {
    setAnnotationEl(null);
    setOpen(false);
  }

  // 點選重新命名
  const renameTextField = useRef(null);
  useEffect(() => {
    if (renameTextField.current) {
      renameTextField.current.getElementsByTagName("input")[0].focus();
    }
  })
  const setRenameAnnotation = (newName, oldName) => {
    if (newName === oldName) {
      setRenameIndex(null);
      return;
    }
    if (newName !== '') {
      renameAnnotation(newName);
      listData[menuTargetIndex].name = newName;
    }
    setRenameIndex(null);
  }

  return (

    <List classes={useStyles()}>
      {
        listItem.length > 0 && listItem.map((row, index) => {
          return (
            <ListItem
              key={index}
              disableRipple
              button
              onClick={event => handleListItemClick(event, listItem[index].id, listItem[index].name, listItem[index].pageIndex)}
              className={selectedAnnotation === row.id ? 'selectedAnnotation' : null}
              disabled={createNewAnnotation || createCopyAnnotation || (renameIndex !== null && index !== renameIndex)}
            >
              <ListItemAvatar>
                <Icon name="AnnotationClass" />
              </ListItemAvatar>
              {index !== renameIndex &&
                <ListItemText primary={row.name} secondary={`${dateformat(row.updatedAt, 'yyyy-mm-dd HH:MM:ss')}`} />}
              {index === renameIndex &&
                <div className={styles.inputArea}>
                  <TextField
                    classes={{ root: textFieldClasses.root }}
                    defaultValue={`${row.name}`} size='small' variant='outlined' label={trans('__rename')}
                    style={{ width: '100%' }}
                    autoFocus={true}
                    ref={renameTextField}
                    onKeyPress={(e) => {
                      if (e.key === 'Escape') { setRenameIndex(null) };
                      if (e.key === 'Enter') { setRenameAnnotation(e.target.value.trim(), row.name) }
                    }} />
                  <div className={styles.inputBtns}>
                    <Button variant='outlined'
                      onClick={() => {
                        setRenameAnnotation(renameTextField.current.querySelector('input').value.trim(), row.name);
                      }}>{trans('__confirm')}</Button>
                    <Button variant='outlined' onClick={() => {
                      setRenameIndex(null)
                    }}>{trans('__cancel')}</Button>
                  </div>
                  <span>{dateformat(Date.now(), 'yyyy-mm-dd HH:MM:ss')}</span>
                </div>
              }
              <IconButton
                aria-label="more"
                aria-haspopup="true"
                onClick={(e) => {
                  e.stopPropagation();
                  openMenu(e, index);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </ListItem>
          );
        })
      }
      <Menu
        anchorEl={annotationEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            width: '135px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 1%), 0px 8px 10px 1px rgb(0 0 0 / 1%), 0px 3px 14px 2px rgb(0 0 0 / 1%)',
          },
        }}
      >
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          closeMenu();
          setRenameIndex(menuTargetIndex);
          setSelectedAnnotation('');
        }} >{trans('__renameRecord')}</MenuItem>
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          closeMenu();
          openCopyAnnotationField();
        }} >{trans('__copyRecord')}</MenuItem>
        <MenuItem onClick={(e) => {
          e.stopPropagation();
          closeMenu();
          setOpenWaring({
            open: true,
            type: 'deleteAnnotation'
          });
        }} style={{ color: 'red' }} >{trans('__delete')}</MenuItem>
      </Menu>
    </List>
  );
}

export default EnhancedTable;
