import i18n from "i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
// import { reactI18nextModule } from "react-i18next";
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    // .use(reactI18nextModule) // passes i18n down to react-i18next
    // .use(LanguageDetector)
    .use(Backend)
    .init({
        lng: 'vi',
        fallbackLng: ['vi-VN', 'en', 'zh-TW'],
        // debug: true,
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
        },
        // keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        load: 'unspecific',
        // special options for react-i18next
        // learn more: https://react.i18next.com/components/i18next-instance
        react: {
            wait: true,
            useSuspense: false,
        }
    });

export default i18n;
