import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { CanvasLevelType } from 'constants/levelTypes';
import {useTranslation} from "react-i18next";
import {fixCssButton} from "../FlipTools";

const LevelTools = ({target:object}) =>{
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.levelTools.${key}`)
    };
    const setLevelHandler = useCallback(levelType => e => {
        EventBus.emit({ event: CanvasEvent.SetCanvasLevelEvent, payload: { object, levelType } });
    },[object])

    return (
        <div className={styles.levelTools}>
            <button style={fixCssButton} onClick={setLevelHandler(CanvasLevelType.FRONT)}>{trans("__moveToFront")}</button>
            <button style={fixCssButton} onClick={setLevelHandler(CanvasLevelType.BACK)}>{trans("__moveToBack")}</button>
        </div>
    )
};


export default LevelTools;