import React, { useCallback, useState, useEffect } from 'react';
import { DefaultPanelColors, DefaultPanelBackgroundColors } from 'constants/colors';
import ColorSelector from 'components/Painter/ColorSelector';
import Select from 'components/common/Select';
import Icon from 'components/Icon';
import { EventBus } from 'events/EventBus';
import { CanvasEvent, PainterEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";


const fontSizes = [12,16,20,24,28,32,36,48,72];

const fontStyleType = {
  normal:'normal',
  italic:'italic'
}

const fontWeightType = {
  normal:'normal',
  bold: 'bold'
}

const TextPanel = ({ eventBusType, activeCanvasObject }) => {
  const { t } = useTranslation();
  const trans = (key) => {
    return t(`components.textPanel.${key}`)
  };

  const [{ insertTextTool }] = useStore(StoreTypes.canvas);
  const [isDirty,setIsDirty] = useState(false);

  useEffect(()=>{
    isDirty && setIsDirty(!isDirty)
  },[isDirty])

  const modifyCanvasObjectProperty = useCallback((propertyName, value )=>{
    if (activeCanvasObject) {
      EventBus.emit({
          eventBusType,
          event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
          payload: {
            object:activeCanvasObject,
            property: propertyName,
            value
          }
      });
      setIsDirty(true)
    }
  },[activeCanvasObject, eventBusType])



  const fontSizeChangeHandler = useCallback(e => {
    const fontSizeValue = parseInt(e.target.value);
    modifyCanvasObjectProperty('fontSize',fontSizeValue)
    EventBus.emit({ event: PainterEvent.ChangeTextFontSizeEvent, payload: { fontSize: fontSizeValue } });
  },[modifyCanvasObjectProperty])

  const toggleFontStyleHandler = useCallback(value => () => {
    const fontStylevValue = value === fontStyleType.normal ? fontStyleType.italic : fontStyleType.normal;
    modifyCanvasObjectProperty('fontStyle', fontStylevValue)
    EventBus.emit({ event: PainterEvent.ChangeTextFontStyleEvent, payload: { fontStyle: fontStylevValue } });
  },[modifyCanvasObjectProperty])

  const toggleFontWeightHandler = useCallback(value => () =>{
    const fontWeightValue = value === fontWeightType.normal ? fontWeightType.bold : fontWeightType.normal;
    modifyCanvasObjectProperty('fontWeight', fontWeightValue)
    EventBus.emit({ event: PainterEvent.ChangeTextFontWeightEvent, payload: { fontWeight: fontWeightValue } });
  },[modifyCanvasObjectProperty])

  const toggleUnderlineHandler = useCallback(value => () =>{
    const underlineBoolean = value ? false : true;
    modifyCanvasObjectProperty('underline', value ? false : true)
    EventBus.emit({ event: PainterEvent.ChangeTextUnderlineEvent, payload: { underline: underlineBoolean } });
  },[modifyCanvasObjectProperty])

  const onColorChangeHandler = useCallback(value=>{
    modifyCanvasObjectProperty('fill', value)
    EventBus.emit({ event: PainterEvent.ChangeTextColorEvent, payload: { color: value } });
  },[modifyCanvasObjectProperty])

  const onBgColorChangeHandler = useCallback(value=>{
    modifyCanvasObjectProperty('backgroundColor', value)
    EventBus.emit({ event: PainterEvent.ChangeTextBgColorEvent, payload: { backgroundColor: value } });
  },[modifyCanvasObjectProperty])

  return activeCanvasObject && (
    <div className={styles.textPanel}>
      <div className={styles.group}>
        <div className={styles.title}>{trans("__textColor")}</div>
        <ColorSelector colors={DefaultPanelColors} value={insertTextTool.fill} onChange={onColorChangeHandler} />
      </div>
      <div className={styles.group}>
        <div className={styles.title}>{trans("__textStyle")}</div>
        <div className={styles.textStyles}>
          <Select className={styles.select} value={insertTextTool.fontSize} onChange={fontSizeChangeHandler}>
            {fontSizes.map(item=>(
              <option value={item}>{item}</option>
            ))}
          </Select>
          <span
            className={classnames(styles.textStyle,{ [styles.active]: insertTextTool.fontWeight === fontWeightType.bold })}
            onClick={toggleFontWeightHandler(insertTextTool.fontWeight)}
          >
            <Icon type="text" name="bold" />
          </span>
          <span
            className={classnames(styles.textStyle,{ [styles.active]: insertTextTool.fontStyle === fontStyleType.italic })}
            onClick={toggleFontStyleHandler(insertTextTool.fontStyle)}
          >
            <Icon type="text" name="italic" />
          </span>
          <span
            className={classnames(styles.textStyle,{ [styles.active]: insertTextTool.underline })}
            onClick={toggleUnderlineHandler(insertTextTool.underline)}
          >
            <Icon type="text" name="underline" />
          </span>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.title}>{trans("__textBackgroundColor")}</div>
        <ColorSelector
          colors={DefaultPanelBackgroundColors}
          value={insertTextTool.backgroundColor}
          customizedColor={false}
          transparentOption={true}
          onChange={onBgColorChangeHandler}
        />
      </div>
    </div>
  )
}

export default TextPanel;