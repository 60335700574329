import React from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";


export const HearingComponent = ({ object, saveProperty }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.propertyForm.hearingPropertyForm.${key}`)
    };

  let hearingInfo = object.hearingInfo ? object.hearingInfo : 0

  const changeHandler = () => e => {
      hearingInfo = e.target.value;
      saveProperty(parseInt(hearingInfo))
  };

  return (
    <>
      <Input className={styles.vocabularyInput} label={trans("__defaultTab")} value={hearingInfo} onChange={changeHandler()} />
    </>
  )
};