import React from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import {useTranslation} from "react-i18next";

export const VolumeOneWordsComponent = ({ object, saveProperty }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.tools.propertyForm.volumeOneWordsPropertyForm.${key}`)
    };

    let volumeOneWords = object.volumeOneWords ? JSON.parse(object.volumeOneWords) : { src: '', word: '', wordsSetSrc: '' }

  const changeHandler = infoType => e => {
    volumeOneWords[infoType] = e.target.value;
    saveProperty(JSON.stringify(volumeOneWords));
  };

  return (
    <>
      <Input className={styles.wordGridInput} label="Src" value={volumeOneWords.src} onChange={changeHandler('src')} />
      <Input className={styles.wordGridInput} label="Word" value={volumeOneWords.word} onChange={changeHandler('word')} />
      <br />
      <Input className={styles.wordGridInput} label={trans("__label")} value={volumeOneWords.wordsSetSrc} onChange={changeHandler('wordsSetSrc')} />
    </>
  )
};
