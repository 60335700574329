import React from 'react';
import { useTranslation } from "react-i18next";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core";

const useWarningStyles = makeStyles({
    paper: {
      width: "522px",
      borderRadius: "16px",
      padding: "24px",
      "& .MuiDialogTitle-root": {
        padding: 0,
        fontSize: "20px",
        marginBottom: "24px",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
        fontSize: "16px",
        fontWeight: "400",
        color: "#637381",
        marginBottom: "24px",
      },
    },
  });

const WarningDialog = ({ open, type, handleCancel, handleConfirm }) => {
    const { t } = useTranslation();
    const trans = (key) => {
        return t(`components.bookshelf.card.${key}`)
    };
    const decideWarningContent = () => {
        let title, content, dialogAction;
        switch (type) {
            case 'token':
                title = trans('__syncError');
                content = trans('__authenticationExpired');
                break;
            case 'deleteAnnotation':
                title = trans('確定要刪除此記錄');
                content = null;
                break;
            case 'deleteBook':
                title = trans('__confirmDeleteBook');
                content = trans('__deleteBookWarning');
                break
            default:
                title = trans('__popupError');
                content = null;
                dialogAction = null;
                break;
        }
        return {
            title,
            content,
            dialogAction
        }
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={useWarningStyles()}
            >
                <DialogTitle id="alert-dialog-title">{decideWarningContent().title}</DialogTitle>
                {decideWarningContent().content && <DialogContent>{decideWarningContent().content}</DialogContent>}
                <DialogActions>
                    {handleCancel &&
                        <Button onClick={() => handleCancel()} variant='outlined' style={{ background: '#D5D7DE', width: '60px' }}>
                            {trans('__cancel')}
                        </Button>}
                    <Button onClick={() => handleConfirm()} style={{ background: '#F44336', color: 'white', width: '60px' }} >
                        {trans('__confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default WarningDialog;
